import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _a66a5856 = () => interopDefault(import('..\\pages\\academy\\index.vue' /* webpackChunkName: "pages_academy_index" */))
const _c3cbdef0 = () => interopDefault(import('..\\pages\\academy-signup.vue' /* webpackChunkName: "pages_academy-signup" */))
const _ebc3bc12 = () => interopDefault(import('..\\pages\\accept.vue' /* webpackChunkName: "pages_accept" */))
const _32f10a50 = () => interopDefault(import('..\\pages\\accept-contract.vue' /* webpackChunkName: "pages_accept-contract" */))
const _1866eaab = () => interopDefault(import('..\\pages\\activate-secondary-mail.vue' /* webpackChunkName: "pages_activate-secondary-mail" */))
const _6b265130 = () => interopDefault(import('..\\pages\\admin.vue' /* webpackChunkName: "pages_admin" */))
const _2f6fee81 = () => interopDefault(import('..\\pages\\biva\\index.vue' /* webpackChunkName: "pages_biva_index" */))
const _509d2913 = () => interopDefault(import('..\\pages\\bluetooth\\index.vue' /* webpackChunkName: "pages_bluetooth_index" */))
const _439cc75e = () => interopDefault(import('..\\pages\\completeProfile.vue' /* webpackChunkName: "pages_completeProfile" */))
const _04d04331 = () => interopDefault(import('..\\pages\\config.vue' /* webpackChunkName: "pages_config" */))
const _0a798088 = () => interopDefault(import('..\\pages\\customer-signup.vue' /* webpackChunkName: "pages_customer-signup" */))
const _39b6fd33 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages_index" */))
const _10ed5759 = () => interopDefault(import('..\\pages\\fitness\\index.vue' /* webpackChunkName: "pages_fitness_index" */))
const _7de86adc = () => interopDefault(import('..\\pages\\forgot.vue' /* webpackChunkName: "pages_forgot" */))
const _8b5de4b4 = () => interopDefault(import('..\\pages\\forgot-password.vue' /* webpackChunkName: "pages_forgot-password" */))
const _237a825f = () => interopDefault(import('..\\pages\\groups\\index.vue' /* webpackChunkName: "pages_groups_index" */))
const _28d828bf = () => interopDefault(import('..\\pages\\HBO-signup.vue' /* webpackChunkName: "pages_HBO-signup" */))
const _5f60827e = () => interopDefault(import('..\\pages\\keto\\index.vue' /* webpackChunkName: "pages_keto_index" */))
const _035ea04a = () => interopDefault(import('..\\pages\\login.vue' /* webpackChunkName: "pages_login" */))
const _5d74baf4 = () => interopDefault(import('..\\pages\\regional\\index.vue' /* webpackChunkName: "pages_regional_index" */))
const _3b0d29a0 = () => interopDefault(import('..\\pages\\reset.vue' /* webpackChunkName: "pages_reset" */))
const _ebc13374 = () => interopDefault(import('..\\pages\\schedule.vue' /* webpackChunkName: "pages_schedule" */))
const _ae30fad2 = () => interopDefault(import('..\\pages\\search.vue' /* webpackChunkName: "pages_search" */))
const _b265cb20 = () => interopDefault(import('..\\pages\\settings\\index.vue' /* webpackChunkName: "pages_settings_index" */))
const _966153f2 = () => interopDefault(import('..\\pages\\signup.vue' /* webpackChunkName: "pages_signup" */))
const _48da3678 = () => interopDefault(import('..\\pages\\update-required.vue' /* webpackChunkName: "pages_update-required" */))
const _1185f60e = () => interopDefault(import('..\\pages\\users\\index.vue' /* webpackChunkName: "pages_users_index" */))
const _4a2e49e4 = () => interopDefault(import('..\\pages\\academy\\material\\index.vue' /* webpackChunkName: "pages_academy_material_index" */))
const _75df5685 = () => interopDefault(import('..\\pages\\fitness\\groups\\index.vue' /* webpackChunkName: "pages_fitness_groups_index" */))
const _49c33634 = () => interopDefault(import('..\\pages\\groups\\deleted.vue' /* webpackChunkName: "pages_groups_deleted" */))
const _e448e6ac = () => interopDefault(import('..\\pages\\keto\\groups\\index.vue' /* webpackChunkName: "pages_keto_groups_index" */))
const _f07a21c0 = () => interopDefault(import('..\\pages\\regional\\groups\\index.vue' /* webpackChunkName: "pages_regional_groups_index" */))
const _46146e56 = () => interopDefault(import('..\\pages\\settings\\documents.vue' /* webpackChunkName: "pages_settings_documents" */))
const _26780024 = () => interopDefault(import('..\\pages\\settings\\licenses.vue' /* webpackChunkName: "pages_settings_licenses" */))
const _279ec78c = () => interopDefault(import('..\\pages\\fitness\\groups\\deleted.vue' /* webpackChunkName: "pages_fitness_groups_deleted" */))
const _31660871 = () => interopDefault(import('..\\pages\\keto\\groups\\deleted.vue' /* webpackChunkName: "pages_keto_groups_deleted" */))
const _4efea567 = () => interopDefault(import('..\\pages\\regional\\groups\\deleted.vue' /* webpackChunkName: "pages_regional_groups_deleted" */))
const _559c2ddc = () => interopDefault(import('..\\pages\\biva\\regional\\_sessionId.vue' /* webpackChunkName: "pages_biva_regional__sessionId" */))
const _2d5eb790 = () => interopDefault(import('..\\pages\\biva\\sessions\\_sessionId.vue' /* webpackChunkName: "pages_biva_sessions__sessionId" */))
const _06439faa = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\index.vue' /* webpackChunkName: "pages_fitness_groups__groupId_index" */))
const _16022b8f = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\index.vue' /* webpackChunkName: "pages_keto_groups__groupId_index" */))
const _49017285 = () => interopDefault(import('..\\pages\\regional\\groups\\_groupId\\index.vue' /* webpackChunkName: "pages_regional_groups__groupId_index" */))
const _30155b71 = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\deleted.vue' /* webpackChunkName: "pages_fitness_groups__groupId_deleted" */))
const _4a608216 = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\deleted.vue' /* webpackChunkName: "pages_keto_groups__groupId_deleted" */))
const _8aac38e8 = () => interopDefault(import('..\\pages\\regional\\groups\\_groupId\\deleted.vue' /* webpackChunkName: "pages_regional_groups__groupId_deleted" */))
const _94cf728a = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\index.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_index" */))
const _e12aae40 = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\index.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_index" */))
const _768a9954 = () => interopDefault(import('..\\pages\\regional\\groups\\_groupId\\patients\\_patientId\\index.vue' /* webpackChunkName: "pages_regional_groups__groupId_patients__patientId_index" */))
const _5f12e78c = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\somatotype.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_somatotype" */))
const _52911487 = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\somatotype.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_somatotype" */))
const _571b7c68 = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\exams\\deleted.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_exams_deleted" */))
const _52cb7947 = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\exams\\deleted.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_exams_deleted" */))
const _e07e58de = () => interopDefault(import('..\\pages\\regional\\groups\\_groupId\\patients\\_patientId\\exams\\deleted.vue' /* webpackChunkName: "pages_regional_groups__groupId_patients__patientId_exams_deleted" */))
const _ccdea1ee = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\bia\\_biaId\\index.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_bia__biaId_index" */))
const _b19a4242 = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\cardio\\_cardioId\\index.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_cardio__cardioId_index" */))
const _aee71142 = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\circumference\\_circumferenceId\\index.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_circumference__circumferenceId_index" */))
const _a2c262d4 = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\index.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_exams__examId_index" */))
const _403ceaa6 = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\fitness\\_fitnessId\\index.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_fitness__fitnessId_index" */))
const _95c71ed0 = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\skinfolds\\_skinfoldId\\index.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_skinfolds__skinfoldId_index" */))
const _1274a9e0 = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\trainings\\_trainingId\\index.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_trainings__trainingId_index" */))
const _513bb104 = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\bia\\_biaId\\index.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_bia__biaId_index" */))
const _7695389a = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\cardio\\_cardioId\\index.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_cardio__cardioId_index" */))
const _60b4a44c = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\circumference\\_circumferenceId\\index.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_circumference__circumferenceId_index" */))
const _53fa46bb = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\index.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_exams__examId_index" */))
const _601461a8 = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\fitness\\_fitnessId\\index.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_fitness__fitnessId_index" */))
const _5163f3bd = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\skinfolds\\_skinfoldId\\index.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_skinfolds__skinfoldId_index" */))
const _a287a5f6 = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\trainings\\_trainingId\\index.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_trainings__trainingId_index" */))
const _8b7ab59e = () => interopDefault(import('..\\pages\\regional\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\index.vue' /* webpackChunkName: "pages_regional_groups__groupId_patients__patientId_exams__examId_index" */))
const _1ce94af1 = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\bia\\_biaId\\bia.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_bia__biaId_bia" */))
const _189a9e4b = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\bia\\_biaId\\fitnessBia.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_bia__biaId_fitnessBia" */))
const _7142b74d = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\bia\\_biaId\\fluidRetention.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_bia__biaId_fluidRetention" */))
const _139a907c = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\bia\\_biaId\\qualitative.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_bia__biaId_qualitative" */))
const _b986ef20 = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\bia\\_biaId\\quantitative.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_bia__biaId_quantitative" */))
const _bf4ea3e8 = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\bia\\_biaId\\reports\\index.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_bia__biaId_reports_index" */))
const _02a4ca4d = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\cardio\\_cardioId\\hr.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_cardio__cardioId_hr" */))
const _dd45b0da = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\circumference\\_circumferenceId\\radar.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_circumference__circumferenceId_radar" */))
const _d3b82c84 = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\bia.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_exams__examId_bia" */))
const _527c2aba = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\circumferenceRadar.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_exams__examId_circumferenceRadar" */))
const _59b9a244 = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\fitnessBia.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_exams__examId_fitnessBia" */))
const _c5ca7340 = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\fluidRetention.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_exams__examId_fluidRetention" */))
const _5decf26f = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\functional.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_exams__examId_functional" */))
const _b7ba6414 = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\hr.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_exams__examId_hr" */))
const _7d62b2d2 = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\load.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_exams__examId_load" */))
const _e6878ce2 = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\qualitative.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_exams__examId_qualitative" */))
const _44397f7a = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\quantitative.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_exams__examId_quantitative" */))
const _679e85c2 = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\reports\\index.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_exams__examId_reports_index" */))
const _2c31456c = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\skinfoldRadar.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_exams__examId_skinfoldRadar" */))
const _2483dede = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\somatotype.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_exams__examId_somatotype" */))
const _358fcb78 = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\zscore.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_exams__examId_zscore" */))
const _c781a690 = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\fitness\\_fitnessId\\functional.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_fitness__fitnessId_functional" */))
const _c425be68 = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\skinfolds\\_skinfoldId\\radar.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_skinfolds__skinfoldId_radar" */))
const _ddc17370 = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\trainings\\_trainingId\\load.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_trainings__trainingId_load" */))
const _69d28fac = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\bia\\_biaId\\bia.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_bia__biaId_bia" */))
const _560ebda0 = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\bia\\_biaId\\fitnessBia.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_bia__biaId_fitnessBia" */))
const _112a809c = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\bia\\_biaId\\fluidRetention.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_bia__biaId_fluidRetention" */))
const _74d5dd06 = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\bia\\_biaId\\qualitative.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_bia__biaId_qualitative" */))
const _7fb533d6 = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\bia\\_biaId\\quantitative.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_bia__biaId_quantitative" */))
const _2680b671 = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\bia\\_biaId\\reports\\index.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_bia__biaId_reports_index" */))
const _afff7b1c = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\cardio\\_cardioId\\hr.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_cardio__cardioId_hr" */))
const _8f1343e4 = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\circumference\\_circumferenceId\\radar.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_circumference__circumferenceId_radar" */))
const _584db1ba = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\bia.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_exams__examId_bia" */))
const _ce50d2c4 = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\circumferenceRadar.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_exams__examId_circumferenceRadar" */))
const _063ca934 = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\estimates.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_exams__examId_estimates" */))
const _535605d9 = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\fitnessBia.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_exams__examId_fitnessBia" */))
const _ec78b04a = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\fluidRetention.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_exams__examId_fluidRetention" */))
const _43c06d2c = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\functional.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_exams__examId_functional" */))
const _5bcdcff1 = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\hr.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_exams__examId_hr" */))
const _5e608c33 = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\ketone.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_exams__examId_ketone" */))
const _1355bbe6 = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\load.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_exams__examId_load" */))
const _12e441f4 = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\qualitative.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_exams__examId_qualitative" */))
const _1cbc447e = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\quantitative.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_exams__examId_quantitative" */))
const _8e4cc2cc = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\reports\\index.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_exams__examId_reports_index" */))
const _6df0e78c = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\somatotype.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_exams__examId_somatotype" */))
const _2e7ac522 = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\weightLoss.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_exams__examId_weightLoss" */))
const _2968b282 = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\zscore.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_exams__examId_zscore" */))
const _44b27a9d = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\fitness\\_fitnessId\\functional.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_fitness__fitnessId_functional" */))
const _3a34a3f1 = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\skinfolds\\_skinfoldId\\radar.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_skinfolds__skinfoldId_radar" */))
const _600f6afa = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\trainings\\_trainingId\\load.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_trainings__trainingId_load" */))
const _7a72ab19 = () => interopDefault(import('..\\pages\\regional\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\bia.vue' /* webpackChunkName: "pages_regional_groups__groupId_patients__patientId_exams__examId_bia" */))
const _74f524e4 = () => interopDefault(import('..\\pages\\regional\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\reports\\index.vue' /* webpackChunkName: "pages_regional_groups__groupId_patients__patientId_exams__examId_reports_index" */))
const _65431074 = () => interopDefault(import('..\\pages\\regional\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\vector.vue' /* webpackChunkName: "pages_regional_groups__groupId_patients__patientId_exams__examId_vector" */))
const _198881a8 = () => interopDefault(import('..\\pages\\regional\\groups\\_groupId\\patients\\_patientId\\histories\\_historyId\\attachments\\index.vue' /* webpackChunkName: "pages_regional_groups__groupId_patients__patientId_histories__historyId_attachments_index" */))
const _2e8b57ac = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\bia\\_biaId\\reports\\_reportSlug\\index.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_bia__biaId_reports__reportSlug_index" */))
const _7e289bf7 = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\reports\\_reportSlug\\index.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_exams__examId_reports__reportSlug_index" */))
const _a3405036 = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\bia\\_biaId\\reports\\_reportSlug\\index.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_bia__biaId_reports__reportSlug_index" */))
const _53758b48 = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\reports\\_reportSlug\\index.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_exams__examId_reports__reportSlug_index" */))
const _3fafbc52 = () => interopDefault(import('..\\pages\\regional\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\reports\\_reportSlug\\index.vue' /* webpackChunkName: "pages_regional_groups__groupId_patients__patientId_exams__examId_reports__reportSlug_index" */))
const _5745497c = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\bia\\_biaId\\reports\\_reportSlug\\iframe.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_bia__biaId_reports__reportSlug_iframe" */))
const _6f9ffb4f = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\reports\\_reportSlug\\iframe.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_exams__examId_reports__reportSlug_iframe" */))
const _46503d21 = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\bia\\_biaId\\reports\\_reportSlug\\iframe.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_bia__biaId_reports__reportSlug_iframe" */))
const _1b17298a = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\reports\\_reportSlug\\iframe.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_exams__examId_reports__reportSlug_iframe" */))
const _42063358 = () => interopDefault(import('..\\pages\\regional\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\reports\\_reportSlug\\iframe.vue' /* webpackChunkName: "pages_regional_groups__groupId_patients__patientId_exams__examId_reports__reportSlug_iframe" */))
const _3a3579f8 = () => interopDefault(import('..\\pages\\groups\\_groupId\\index.vue' /* webpackChunkName: "pages_groups__groupId_index" */))
const _1cb8a1ac = () => interopDefault(import('..\\pages\\groups\\_groupId\\bia.vue' /* webpackChunkName: "pages_groups__groupId_bia" */))
const _6adb904b = () => interopDefault(import('..\\pages\\groups\\_groupId\\deleted.vue' /* webpackChunkName: "pages_groups__groupId_deleted" */))
const _c504ead6 = () => interopDefault(import('..\\pages\\groups\\_groupId\\patients\\_patientId\\index.vue' /* webpackChunkName: "pages_groups__groupId_patients__patientId_index" */))
const _175ef6f0 = () => interopDefault(import('..\\pages\\groups\\_groupId\\patients\\_patientId\\activities\\index.vue' /* webpackChunkName: "pages_groups__groupId_patients__patientId_activities_index" */))
const _179c8654 = () => interopDefault(import('..\\pages\\groups\\_groupId\\patients\\_patientId\\bodyscan\\index.vue' /* webpackChunkName: "pages_groups__groupId_patients__patientId_bodyscan_index" */))
const _00c09153 = () => interopDefault(import('..\\pages\\groups\\_groupId\\patients\\_patientId\\grid.vue' /* webpackChunkName: "pages_groups__groupId_patients__patientId_grid" */))
const _ee4374b8 = () => interopDefault(import('..\\pages\\groups\\_groupId\\patients\\_patientId\\schedule.vue' /* webpackChunkName: "pages_groups__groupId_patients__patientId_schedule" */))
const _038c92f2 = () => interopDefault(import('..\\pages\\groups\\_groupId\\patients\\_patientId\\bodyscan\\grid.vue' /* webpackChunkName: "pages_groups__groupId_patients__patientId_bodyscan_grid" */))
const _3d52b332 = () => interopDefault(import('..\\pages\\groups\\_groupId\\patients\\_patientId\\exams\\deleted.vue' /* webpackChunkName: "pages_groups__groupId_patients__patientId_exams_deleted" */))
const _1b079974 = () => interopDefault(import('..\\pages\\groups\\_groupId\\patients\\_patientId\\bodyscan\\_bodyscanExamId\\index.vue' /* webpackChunkName: "pages_groups__groupId_patients__patientId_bodyscan__bodyscanExamId_index" */))
const _52451e70 = () => interopDefault(import('..\\pages\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\index.vue' /* webpackChunkName: "pages_groups__groupId_patients__patientId_exams__examId_index" */))
const _27637eda = () => interopDefault(import('..\\pages\\groups\\_groupId\\patients\\_patientId\\overview\\_formulaId\\index.vue' /* webpackChunkName: "pages_groups__groupId_patients__patientId_overview__formulaId_index" */))
const _33a19e6f = () => interopDefault(import('..\\pages\\groups\\_groupId\\patients\\_patientId\\bodyscan\\_bodyscanExamId\\reports\\index.vue' /* webpackChunkName: "pages_groups__groupId_patients__patientId_bodyscan__bodyscanExamId_reports_index" */))
const _3af06818 = () => interopDefault(import('..\\pages\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\bia.vue' /* webpackChunkName: "pages_groups__groupId_patients__patientId_exams__examId_bia" */))
const _a18ac32e = () => interopDefault(import('..\\pages\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\estimates.vue' /* webpackChunkName: "pages_groups__groupId_patients__patientId_exams__examId_estimates" */))
const _49988080 = () => interopDefault(import('..\\pages\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\hydra.vue' /* webpackChunkName: "pages_groups__groupId_patients__patientId_exams__examId_hydra" */))
const _30be9efd = () => interopDefault(import('..\\pages\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\interpretive.vue' /* webpackChunkName: "pages_groups__groupId_patients__patientId_exams__examId_interpretive" */))
const _9944f5b2 = () => interopDefault(import('..\\pages\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\measures.vue' /* webpackChunkName: "pages_groups__groupId_patients__patientId_exams__examId_measures" */))
const _e4f06418 = () => interopDefault(import('..\\pages\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\percentiles.vue' /* webpackChunkName: "pages_groups__groupId_patients__patientId_exams__examId_percentiles" */))
const _d884baf6 = () => interopDefault(import('..\\pages\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\reports\\index.vue' /* webpackChunkName: "pages_groups__groupId_patients__patientId_exams__examId_reports_index" */))
const _23ad9c27 = () => interopDefault(import('..\\pages\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\reports\\edit.vue' /* webpackChunkName: "pages_groups__groupId_patients__patientId_exams__examId_reports_edit" */))
const _1b143db0 = () => interopDefault(import('..\\pages\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\reports\\default\\_reportSlug\\index.vue' /* webpackChunkName: "pages_groups__groupId_patients__patientId_exams__examId_reports_default__reportSlug_index" */))
const _f6094784 = () => interopDefault(import('..\\pages\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\reports\\default\\_reportSlug\\iframe.vue' /* webpackChunkName: "pages_groups__groupId_patients__patientId_exams__examId_reports_default__reportSlug_iframe" */))
const _cb6a3d68 = () => interopDefault(import('..\\pages\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\reports\\default\\_reportSlug\\new\\index.vue' /* webpackChunkName: "pages_groups__groupId_patients__patientId_exams__examId_reports_default__reportSlug_new_index" */))
const _303c5ca7 = () => interopDefault(import('..\\pages\\groups\\_groupId\\patients\\_patientId\\bodyscan\\_bodyscanExamId\\reports\\_reportSlug\\index.vue' /* webpackChunkName: "pages_groups__groupId_patients__patientId_bodyscan__bodyscanExamId_reports__reportSlug_index" */))
const _923e8f3e = () => interopDefault(import('..\\pages\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\reports\\_reportId\\index.vue' /* webpackChunkName: "pages_groups__groupId_patients__patientId_exams__examId_reports__reportId_index" */))
const _0004509f = () => interopDefault(import('..\\pages\\groups\\_groupId\\patients\\_patientId\\bodyscan\\_bodyscanExamId\\reports\\_reportSlug\\iframe.vue' /* webpackChunkName: "pages_groups__groupId_patients__patientId_bodyscan__bodyscanExamId_reports__reportSlug_iframe" */))
const _4deb6c25 = () => interopDefault(import('..\\pages\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\reports\\_reportId\\iframe.vue' /* webpackChunkName: "pages_groups__groupId_patients__patientId_exams__examId_reports__reportId_iframe" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/academy",
    component: _a66a5856,
    name: "academy___en"
  }, {
    path: "/academy-signup",
    component: _c3cbdef0,
    name: "academy-signup___en"
  }, {
    path: "/accept",
    component: _ebc3bc12,
    name: "accept___en"
  }, {
    path: "/accept-contract",
    component: _32f10a50,
    name: "accept-contract___en"
  }, {
    path: "/activate-secondary-mail",
    component: _1866eaab,
    name: "activate-secondary-mail___en"
  }, {
    path: "/admin",
    component: _6b265130,
    name: "admin___en"
  }, {
    path: "/biva",
    component: _2f6fee81,
    name: "biva___en"
  }, {
    path: "/bluetooth",
    component: _509d2913,
    name: "bluetooth___en"
  }, {
    path: "/completeProfile",
    component: _439cc75e,
    name: "completeProfile___en"
  }, {
    path: "/config",
    component: _04d04331,
    name: "config___en"
  }, {
    path: "/customer-signup",
    component: _0a798088,
    name: "customer-signup___en"
  }, {
    path: "/de",
    component: _39b6fd33,
    name: "index___de"
  }, {
    path: "/el",
    component: _39b6fd33,
    name: "index___el"
  }, {
    path: "/en-gb",
    component: _39b6fd33,
    name: "index___en-gb"
  }, {
    path: "/es",
    component: _39b6fd33,
    name: "index___es"
  }, {
    path: "/fitness",
    component: _10ed5759,
    name: "fitness___en"
  }, {
    path: "/forgot",
    component: _7de86adc,
    name: "forgot___en"
  }, {
    path: "/forgot-password",
    component: _8b5de4b4,
    name: "forgot-password___en"
  }, {
    path: "/fr",
    component: _39b6fd33,
    name: "index___fr"
  }, {
    path: "/groups",
    component: _237a825f,
    name: "groups___en"
  }, {
    path: "/HBO-signup",
    component: _28d828bf,
    name: "HBO-signup___en"
  }, {
    path: "/it",
    component: _39b6fd33,
    name: "index___it"
  }, {
    path: "/keto",
    component: _5f60827e,
    name: "keto___en"
  }, {
    path: "/login",
    component: _035ea04a,
    name: "login___en"
  }, {
    path: "/nl",
    component: _39b6fd33,
    name: "index___nl"
  }, {
    path: "/nl-be",
    component: _39b6fd33,
    name: "index___nl-be"
  }, {
    path: "/pl",
    component: _39b6fd33,
    name: "index___pl"
  }, {
    path: "/regional",
    component: _5d74baf4,
    name: "regional___en"
  }, {
    path: "/reset",
    component: _3b0d29a0,
    name: "reset___en"
  }, {
    path: "/schedule",
    component: _ebc13374,
    name: "schedule___en"
  }, {
    path: "/search",
    component: _ae30fad2,
    name: "search___en"
  }, {
    path: "/settings",
    component: _b265cb20,
    name: "settings___en"
  }, {
    path: "/signup",
    component: _966153f2,
    name: "signup___en"
  }, {
    path: "/update-required",
    component: _48da3678,
    name: "update-required___en"
  }, {
    path: "/users",
    component: _1185f60e,
    name: "users___en"
  }, {
    path: "/zh",
    component: _39b6fd33,
    name: "index___zh"
  }, {
    path: "/academy/material",
    component: _4a2e49e4,
    name: "academy-material___en"
  }, {
    path: "/de/academy",
    component: _a66a5856,
    name: "academy___de"
  }, {
    path: "/de/academy-signup",
    component: _c3cbdef0,
    name: "academy-signup___de"
  }, {
    path: "/de/accept",
    component: _ebc3bc12,
    name: "accept___de"
  }, {
    path: "/de/accept-contract",
    component: _32f10a50,
    name: "accept-contract___de"
  }, {
    path: "/de/activate-secondary-mail",
    component: _1866eaab,
    name: "activate-secondary-mail___de"
  }, {
    path: "/de/admin",
    component: _6b265130,
    name: "admin___de"
  }, {
    path: "/de/biva",
    component: _2f6fee81,
    name: "biva___de"
  }, {
    path: "/de/bluetooth",
    component: _509d2913,
    name: "bluetooth___de"
  }, {
    path: "/de/completeProfile",
    component: _439cc75e,
    name: "completeProfile___de"
  }, {
    path: "/de/config",
    component: _04d04331,
    name: "config___de"
  }, {
    path: "/de/customer-signup",
    component: _0a798088,
    name: "customer-signup___de"
  }, {
    path: "/de/fitness",
    component: _10ed5759,
    name: "fitness___de"
  }, {
    path: "/de/forgot",
    component: _7de86adc,
    name: "forgot___de"
  }, {
    path: "/de/forgot-password",
    component: _8b5de4b4,
    name: "forgot-password___de"
  }, {
    path: "/de/groups",
    component: _237a825f,
    name: "groups___de"
  }, {
    path: "/de/HBO-signup",
    component: _28d828bf,
    name: "HBO-signup___de"
  }, {
    path: "/de/keto",
    component: _5f60827e,
    name: "keto___de"
  }, {
    path: "/de/login",
    component: _035ea04a,
    name: "login___de"
  }, {
    path: "/de/regional",
    component: _5d74baf4,
    name: "regional___de"
  }, {
    path: "/de/reset",
    component: _3b0d29a0,
    name: "reset___de"
  }, {
    path: "/de/schedule",
    component: _ebc13374,
    name: "schedule___de"
  }, {
    path: "/de/search",
    component: _ae30fad2,
    name: "search___de"
  }, {
    path: "/de/settings",
    component: _b265cb20,
    name: "settings___de"
  }, {
    path: "/de/signup",
    component: _966153f2,
    name: "signup___de"
  }, {
    path: "/de/update-required",
    component: _48da3678,
    name: "update-required___de"
  }, {
    path: "/de/users",
    component: _1185f60e,
    name: "users___de"
  }, {
    path: "/el/academy",
    component: _a66a5856,
    name: "academy___el"
  }, {
    path: "/el/academy-signup",
    component: _c3cbdef0,
    name: "academy-signup___el"
  }, {
    path: "/el/accept",
    component: _ebc3bc12,
    name: "accept___el"
  }, {
    path: "/el/accept-contract",
    component: _32f10a50,
    name: "accept-contract___el"
  }, {
    path: "/el/activate-secondary-mail",
    component: _1866eaab,
    name: "activate-secondary-mail___el"
  }, {
    path: "/el/admin",
    component: _6b265130,
    name: "admin___el"
  }, {
    path: "/el/biva",
    component: _2f6fee81,
    name: "biva___el"
  }, {
    path: "/el/bluetooth",
    component: _509d2913,
    name: "bluetooth___el"
  }, {
    path: "/el/completeProfile",
    component: _439cc75e,
    name: "completeProfile___el"
  }, {
    path: "/el/config",
    component: _04d04331,
    name: "config___el"
  }, {
    path: "/el/customer-signup",
    component: _0a798088,
    name: "customer-signup___el"
  }, {
    path: "/el/fitness",
    component: _10ed5759,
    name: "fitness___el"
  }, {
    path: "/el/forgot",
    component: _7de86adc,
    name: "forgot___el"
  }, {
    path: "/el/forgot-password",
    component: _8b5de4b4,
    name: "forgot-password___el"
  }, {
    path: "/el/groups",
    component: _237a825f,
    name: "groups___el"
  }, {
    path: "/el/HBO-signup",
    component: _28d828bf,
    name: "HBO-signup___el"
  }, {
    path: "/el/keto",
    component: _5f60827e,
    name: "keto___el"
  }, {
    path: "/el/login",
    component: _035ea04a,
    name: "login___el"
  }, {
    path: "/el/regional",
    component: _5d74baf4,
    name: "regional___el"
  }, {
    path: "/el/reset",
    component: _3b0d29a0,
    name: "reset___el"
  }, {
    path: "/el/schedule",
    component: _ebc13374,
    name: "schedule___el"
  }, {
    path: "/el/search",
    component: _ae30fad2,
    name: "search___el"
  }, {
    path: "/el/settings",
    component: _b265cb20,
    name: "settings___el"
  }, {
    path: "/el/signup",
    component: _966153f2,
    name: "signup___el"
  }, {
    path: "/el/update-required",
    component: _48da3678,
    name: "update-required___el"
  }, {
    path: "/el/users",
    component: _1185f60e,
    name: "users___el"
  }, {
    path: "/en-gb/academy",
    component: _a66a5856,
    name: "academy___en-gb"
  }, {
    path: "/en-gb/academy-signup",
    component: _c3cbdef0,
    name: "academy-signup___en-gb"
  }, {
    path: "/en-gb/accept",
    component: _ebc3bc12,
    name: "accept___en-gb"
  }, {
    path: "/en-gb/accept-contract",
    component: _32f10a50,
    name: "accept-contract___en-gb"
  }, {
    path: "/en-gb/activate-secondary-mail",
    component: _1866eaab,
    name: "activate-secondary-mail___en-gb"
  }, {
    path: "/en-gb/admin",
    component: _6b265130,
    name: "admin___en-gb"
  }, {
    path: "/en-gb/biva",
    component: _2f6fee81,
    name: "biva___en-gb"
  }, {
    path: "/en-gb/bluetooth",
    component: _509d2913,
    name: "bluetooth___en-gb"
  }, {
    path: "/en-gb/completeProfile",
    component: _439cc75e,
    name: "completeProfile___en-gb"
  }, {
    path: "/en-gb/config",
    component: _04d04331,
    name: "config___en-gb"
  }, {
    path: "/en-gb/customer-signup",
    component: _0a798088,
    name: "customer-signup___en-gb"
  }, {
    path: "/en-gb/fitness",
    component: _10ed5759,
    name: "fitness___en-gb"
  }, {
    path: "/en-gb/forgot",
    component: _7de86adc,
    name: "forgot___en-gb"
  }, {
    path: "/en-gb/forgot-password",
    component: _8b5de4b4,
    name: "forgot-password___en-gb"
  }, {
    path: "/en-gb/groups",
    component: _237a825f,
    name: "groups___en-gb"
  }, {
    path: "/en-gb/HBO-signup",
    component: _28d828bf,
    name: "HBO-signup___en-gb"
  }, {
    path: "/en-gb/keto",
    component: _5f60827e,
    name: "keto___en-gb"
  }, {
    path: "/en-gb/login",
    component: _035ea04a,
    name: "login___en-gb"
  }, {
    path: "/en-gb/regional",
    component: _5d74baf4,
    name: "regional___en-gb"
  }, {
    path: "/en-gb/reset",
    component: _3b0d29a0,
    name: "reset___en-gb"
  }, {
    path: "/en-gb/schedule",
    component: _ebc13374,
    name: "schedule___en-gb"
  }, {
    path: "/en-gb/search",
    component: _ae30fad2,
    name: "search___en-gb"
  }, {
    path: "/en-gb/settings",
    component: _b265cb20,
    name: "settings___en-gb"
  }, {
    path: "/en-gb/signup",
    component: _966153f2,
    name: "signup___en-gb"
  }, {
    path: "/en-gb/update-required",
    component: _48da3678,
    name: "update-required___en-gb"
  }, {
    path: "/en-gb/users",
    component: _1185f60e,
    name: "users___en-gb"
  }, {
    path: "/es/academy",
    component: _a66a5856,
    name: "academy___es"
  }, {
    path: "/es/academy-signup",
    component: _c3cbdef0,
    name: "academy-signup___es"
  }, {
    path: "/es/accept",
    component: _ebc3bc12,
    name: "accept___es"
  }, {
    path: "/es/accept-contract",
    component: _32f10a50,
    name: "accept-contract___es"
  }, {
    path: "/es/activate-secondary-mail",
    component: _1866eaab,
    name: "activate-secondary-mail___es"
  }, {
    path: "/es/admin",
    component: _6b265130,
    name: "admin___es"
  }, {
    path: "/es/biva",
    component: _2f6fee81,
    name: "biva___es"
  }, {
    path: "/es/bluetooth",
    component: _509d2913,
    name: "bluetooth___es"
  }, {
    path: "/es/completeProfile",
    component: _439cc75e,
    name: "completeProfile___es"
  }, {
    path: "/es/config",
    component: _04d04331,
    name: "config___es"
  }, {
    path: "/es/customer-signup",
    component: _0a798088,
    name: "customer-signup___es"
  }, {
    path: "/es/fitness",
    component: _10ed5759,
    name: "fitness___es"
  }, {
    path: "/es/forgot",
    component: _7de86adc,
    name: "forgot___es"
  }, {
    path: "/es/forgot-password",
    component: _8b5de4b4,
    name: "forgot-password___es"
  }, {
    path: "/es/groups",
    component: _237a825f,
    name: "groups___es"
  }, {
    path: "/es/HBO-signup",
    component: _28d828bf,
    name: "HBO-signup___es"
  }, {
    path: "/es/keto",
    component: _5f60827e,
    name: "keto___es"
  }, {
    path: "/es/login",
    component: _035ea04a,
    name: "login___es"
  }, {
    path: "/es/regional",
    component: _5d74baf4,
    name: "regional___es"
  }, {
    path: "/es/reset",
    component: _3b0d29a0,
    name: "reset___es"
  }, {
    path: "/es/schedule",
    component: _ebc13374,
    name: "schedule___es"
  }, {
    path: "/es/search",
    component: _ae30fad2,
    name: "search___es"
  }, {
    path: "/es/settings",
    component: _b265cb20,
    name: "settings___es"
  }, {
    path: "/es/signup",
    component: _966153f2,
    name: "signup___es"
  }, {
    path: "/es/update-required",
    component: _48da3678,
    name: "update-required___es"
  }, {
    path: "/es/users",
    component: _1185f60e,
    name: "users___es"
  }, {
    path: "/fitness/groups",
    component: _75df5685,
    name: "fitness-groups___en"
  }, {
    path: "/fr/academy",
    component: _a66a5856,
    name: "academy___fr"
  }, {
    path: "/fr/academy-signup",
    component: _c3cbdef0,
    name: "academy-signup___fr"
  }, {
    path: "/fr/accept",
    component: _ebc3bc12,
    name: "accept___fr"
  }, {
    path: "/fr/accept-contract",
    component: _32f10a50,
    name: "accept-contract___fr"
  }, {
    path: "/fr/activate-secondary-mail",
    component: _1866eaab,
    name: "activate-secondary-mail___fr"
  }, {
    path: "/fr/admin",
    component: _6b265130,
    name: "admin___fr"
  }, {
    path: "/fr/biva",
    component: _2f6fee81,
    name: "biva___fr"
  }, {
    path: "/fr/bluetooth",
    component: _509d2913,
    name: "bluetooth___fr"
  }, {
    path: "/fr/completeProfile",
    component: _439cc75e,
    name: "completeProfile___fr"
  }, {
    path: "/fr/config",
    component: _04d04331,
    name: "config___fr"
  }, {
    path: "/fr/customer-signup",
    component: _0a798088,
    name: "customer-signup___fr"
  }, {
    path: "/fr/fitness",
    component: _10ed5759,
    name: "fitness___fr"
  }, {
    path: "/fr/forgot",
    component: _7de86adc,
    name: "forgot___fr"
  }, {
    path: "/fr/forgot-password",
    component: _8b5de4b4,
    name: "forgot-password___fr"
  }, {
    path: "/fr/groups",
    component: _237a825f,
    name: "groups___fr"
  }, {
    path: "/fr/HBO-signup",
    component: _28d828bf,
    name: "HBO-signup___fr"
  }, {
    path: "/fr/keto",
    component: _5f60827e,
    name: "keto___fr"
  }, {
    path: "/fr/login",
    component: _035ea04a,
    name: "login___fr"
  }, {
    path: "/fr/regional",
    component: _5d74baf4,
    name: "regional___fr"
  }, {
    path: "/fr/reset",
    component: _3b0d29a0,
    name: "reset___fr"
  }, {
    path: "/fr/schedule",
    component: _ebc13374,
    name: "schedule___fr"
  }, {
    path: "/fr/search",
    component: _ae30fad2,
    name: "search___fr"
  }, {
    path: "/fr/settings",
    component: _b265cb20,
    name: "settings___fr"
  }, {
    path: "/fr/signup",
    component: _966153f2,
    name: "signup___fr"
  }, {
    path: "/fr/update-required",
    component: _48da3678,
    name: "update-required___fr"
  }, {
    path: "/fr/users",
    component: _1185f60e,
    name: "users___fr"
  }, {
    path: "/groups/deleted",
    component: _49c33634,
    name: "groups-deleted___en"
  }, {
    path: "/it/academy",
    component: _a66a5856,
    name: "academy___it"
  }, {
    path: "/it/academy-signup",
    component: _c3cbdef0,
    name: "academy-signup___it"
  }, {
    path: "/it/accept",
    component: _ebc3bc12,
    name: "accept___it"
  }, {
    path: "/it/accept-contract",
    component: _32f10a50,
    name: "accept-contract___it"
  }, {
    path: "/it/activate-secondary-mail",
    component: _1866eaab,
    name: "activate-secondary-mail___it"
  }, {
    path: "/it/admin",
    component: _6b265130,
    name: "admin___it"
  }, {
    path: "/it/biva",
    component: _2f6fee81,
    name: "biva___it"
  }, {
    path: "/it/bluetooth",
    component: _509d2913,
    name: "bluetooth___it"
  }, {
    path: "/it/completeProfile",
    component: _439cc75e,
    name: "completeProfile___it"
  }, {
    path: "/it/config",
    component: _04d04331,
    name: "config___it"
  }, {
    path: "/it/customer-signup",
    component: _0a798088,
    name: "customer-signup___it"
  }, {
    path: "/it/fitness",
    component: _10ed5759,
    name: "fitness___it"
  }, {
    path: "/it/forgot",
    component: _7de86adc,
    name: "forgot___it"
  }, {
    path: "/it/forgot-password",
    component: _8b5de4b4,
    name: "forgot-password___it"
  }, {
    path: "/it/groups",
    component: _237a825f,
    name: "groups___it"
  }, {
    path: "/it/HBO-signup",
    component: _28d828bf,
    name: "HBO-signup___it"
  }, {
    path: "/it/keto",
    component: _5f60827e,
    name: "keto___it"
  }, {
    path: "/it/login",
    component: _035ea04a,
    name: "login___it"
  }, {
    path: "/it/regional",
    component: _5d74baf4,
    name: "regional___it"
  }, {
    path: "/it/reset",
    component: _3b0d29a0,
    name: "reset___it"
  }, {
    path: "/it/schedule",
    component: _ebc13374,
    name: "schedule___it"
  }, {
    path: "/it/search",
    component: _ae30fad2,
    name: "search___it"
  }, {
    path: "/it/settings",
    component: _b265cb20,
    name: "settings___it"
  }, {
    path: "/it/signup",
    component: _966153f2,
    name: "signup___it"
  }, {
    path: "/it/update-required",
    component: _48da3678,
    name: "update-required___it"
  }, {
    path: "/it/users",
    component: _1185f60e,
    name: "users___it"
  }, {
    path: "/keto/groups",
    component: _e448e6ac,
    name: "keto-groups___en"
  }, {
    path: "/nl-be/academy",
    component: _a66a5856,
    name: "academy___nl-be"
  }, {
    path: "/nl-be/academy-signup",
    component: _c3cbdef0,
    name: "academy-signup___nl-be"
  }, {
    path: "/nl-be/accept",
    component: _ebc3bc12,
    name: "accept___nl-be"
  }, {
    path: "/nl-be/accept-contract",
    component: _32f10a50,
    name: "accept-contract___nl-be"
  }, {
    path: "/nl-be/activate-secondary-mail",
    component: _1866eaab,
    name: "activate-secondary-mail___nl-be"
  }, {
    path: "/nl-be/admin",
    component: _6b265130,
    name: "admin___nl-be"
  }, {
    path: "/nl-be/biva",
    component: _2f6fee81,
    name: "biva___nl-be"
  }, {
    path: "/nl-be/bluetooth",
    component: _509d2913,
    name: "bluetooth___nl-be"
  }, {
    path: "/nl-be/completeProfile",
    component: _439cc75e,
    name: "completeProfile___nl-be"
  }, {
    path: "/nl-be/config",
    component: _04d04331,
    name: "config___nl-be"
  }, {
    path: "/nl-be/customer-signup",
    component: _0a798088,
    name: "customer-signup___nl-be"
  }, {
    path: "/nl-be/fitness",
    component: _10ed5759,
    name: "fitness___nl-be"
  }, {
    path: "/nl-be/forgot",
    component: _7de86adc,
    name: "forgot___nl-be"
  }, {
    path: "/nl-be/forgot-password",
    component: _8b5de4b4,
    name: "forgot-password___nl-be"
  }, {
    path: "/nl-be/groups",
    component: _237a825f,
    name: "groups___nl-be"
  }, {
    path: "/nl-be/HBO-signup",
    component: _28d828bf,
    name: "HBO-signup___nl-be"
  }, {
    path: "/nl-be/keto",
    component: _5f60827e,
    name: "keto___nl-be"
  }, {
    path: "/nl-be/login",
    component: _035ea04a,
    name: "login___nl-be"
  }, {
    path: "/nl-be/regional",
    component: _5d74baf4,
    name: "regional___nl-be"
  }, {
    path: "/nl-be/reset",
    component: _3b0d29a0,
    name: "reset___nl-be"
  }, {
    path: "/nl-be/schedule",
    component: _ebc13374,
    name: "schedule___nl-be"
  }, {
    path: "/nl-be/search",
    component: _ae30fad2,
    name: "search___nl-be"
  }, {
    path: "/nl-be/settings",
    component: _b265cb20,
    name: "settings___nl-be"
  }, {
    path: "/nl-be/signup",
    component: _966153f2,
    name: "signup___nl-be"
  }, {
    path: "/nl-be/update-required",
    component: _48da3678,
    name: "update-required___nl-be"
  }, {
    path: "/nl-be/users",
    component: _1185f60e,
    name: "users___nl-be"
  }, {
    path: "/nl/academy",
    component: _a66a5856,
    name: "academy___nl"
  }, {
    path: "/nl/academy-signup",
    component: _c3cbdef0,
    name: "academy-signup___nl"
  }, {
    path: "/nl/accept",
    component: _ebc3bc12,
    name: "accept___nl"
  }, {
    path: "/nl/accept-contract",
    component: _32f10a50,
    name: "accept-contract___nl"
  }, {
    path: "/nl/activate-secondary-mail",
    component: _1866eaab,
    name: "activate-secondary-mail___nl"
  }, {
    path: "/nl/admin",
    component: _6b265130,
    name: "admin___nl"
  }, {
    path: "/nl/biva",
    component: _2f6fee81,
    name: "biva___nl"
  }, {
    path: "/nl/bluetooth",
    component: _509d2913,
    name: "bluetooth___nl"
  }, {
    path: "/nl/completeProfile",
    component: _439cc75e,
    name: "completeProfile___nl"
  }, {
    path: "/nl/config",
    component: _04d04331,
    name: "config___nl"
  }, {
    path: "/nl/customer-signup",
    component: _0a798088,
    name: "customer-signup___nl"
  }, {
    path: "/nl/fitness",
    component: _10ed5759,
    name: "fitness___nl"
  }, {
    path: "/nl/forgot",
    component: _7de86adc,
    name: "forgot___nl"
  }, {
    path: "/nl/forgot-password",
    component: _8b5de4b4,
    name: "forgot-password___nl"
  }, {
    path: "/nl/groups",
    component: _237a825f,
    name: "groups___nl"
  }, {
    path: "/nl/HBO-signup",
    component: _28d828bf,
    name: "HBO-signup___nl"
  }, {
    path: "/nl/keto",
    component: _5f60827e,
    name: "keto___nl"
  }, {
    path: "/nl/login",
    component: _035ea04a,
    name: "login___nl"
  }, {
    path: "/nl/regional",
    component: _5d74baf4,
    name: "regional___nl"
  }, {
    path: "/nl/reset",
    component: _3b0d29a0,
    name: "reset___nl"
  }, {
    path: "/nl/schedule",
    component: _ebc13374,
    name: "schedule___nl"
  }, {
    path: "/nl/search",
    component: _ae30fad2,
    name: "search___nl"
  }, {
    path: "/nl/settings",
    component: _b265cb20,
    name: "settings___nl"
  }, {
    path: "/nl/signup",
    component: _966153f2,
    name: "signup___nl"
  }, {
    path: "/nl/update-required",
    component: _48da3678,
    name: "update-required___nl"
  }, {
    path: "/nl/users",
    component: _1185f60e,
    name: "users___nl"
  }, {
    path: "/pl/academy",
    component: _a66a5856,
    name: "academy___pl"
  }, {
    path: "/pl/academy-signup",
    component: _c3cbdef0,
    name: "academy-signup___pl"
  }, {
    path: "/pl/accept",
    component: _ebc3bc12,
    name: "accept___pl"
  }, {
    path: "/pl/accept-contract",
    component: _32f10a50,
    name: "accept-contract___pl"
  }, {
    path: "/pl/activate-secondary-mail",
    component: _1866eaab,
    name: "activate-secondary-mail___pl"
  }, {
    path: "/pl/admin",
    component: _6b265130,
    name: "admin___pl"
  }, {
    path: "/pl/biva",
    component: _2f6fee81,
    name: "biva___pl"
  }, {
    path: "/pl/bluetooth",
    component: _509d2913,
    name: "bluetooth___pl"
  }, {
    path: "/pl/completeProfile",
    component: _439cc75e,
    name: "completeProfile___pl"
  }, {
    path: "/pl/config",
    component: _04d04331,
    name: "config___pl"
  }, {
    path: "/pl/customer-signup",
    component: _0a798088,
    name: "customer-signup___pl"
  }, {
    path: "/pl/fitness",
    component: _10ed5759,
    name: "fitness___pl"
  }, {
    path: "/pl/forgot",
    component: _7de86adc,
    name: "forgot___pl"
  }, {
    path: "/pl/forgot-password",
    component: _8b5de4b4,
    name: "forgot-password___pl"
  }, {
    path: "/pl/groups",
    component: _237a825f,
    name: "groups___pl"
  }, {
    path: "/pl/HBO-signup",
    component: _28d828bf,
    name: "HBO-signup___pl"
  }, {
    path: "/pl/keto",
    component: _5f60827e,
    name: "keto___pl"
  }, {
    path: "/pl/login",
    component: _035ea04a,
    name: "login___pl"
  }, {
    path: "/pl/regional",
    component: _5d74baf4,
    name: "regional___pl"
  }, {
    path: "/pl/reset",
    component: _3b0d29a0,
    name: "reset___pl"
  }, {
    path: "/pl/schedule",
    component: _ebc13374,
    name: "schedule___pl"
  }, {
    path: "/pl/search",
    component: _ae30fad2,
    name: "search___pl"
  }, {
    path: "/pl/settings",
    component: _b265cb20,
    name: "settings___pl"
  }, {
    path: "/pl/signup",
    component: _966153f2,
    name: "signup___pl"
  }, {
    path: "/pl/update-required",
    component: _48da3678,
    name: "update-required___pl"
  }, {
    path: "/pl/users",
    component: _1185f60e,
    name: "users___pl"
  }, {
    path: "/regional/groups",
    component: _f07a21c0,
    name: "regional-groups___en"
  }, {
    path: "/settings/documents",
    component: _46146e56,
    name: "settings-documents___en"
  }, {
    path: "/settings/licenses",
    component: _26780024,
    name: "settings-licenses___en"
  }, {
    path: "/zh/academy",
    component: _a66a5856,
    name: "academy___zh"
  }, {
    path: "/zh/academy-signup",
    component: _c3cbdef0,
    name: "academy-signup___zh"
  }, {
    path: "/zh/accept",
    component: _ebc3bc12,
    name: "accept___zh"
  }, {
    path: "/zh/accept-contract",
    component: _32f10a50,
    name: "accept-contract___zh"
  }, {
    path: "/zh/activate-secondary-mail",
    component: _1866eaab,
    name: "activate-secondary-mail___zh"
  }, {
    path: "/zh/admin",
    component: _6b265130,
    name: "admin___zh"
  }, {
    path: "/zh/biva",
    component: _2f6fee81,
    name: "biva___zh"
  }, {
    path: "/zh/bluetooth",
    component: _509d2913,
    name: "bluetooth___zh"
  }, {
    path: "/zh/completeProfile",
    component: _439cc75e,
    name: "completeProfile___zh"
  }, {
    path: "/zh/config",
    component: _04d04331,
    name: "config___zh"
  }, {
    path: "/zh/customer-signup",
    component: _0a798088,
    name: "customer-signup___zh"
  }, {
    path: "/zh/fitness",
    component: _10ed5759,
    name: "fitness___zh"
  }, {
    path: "/zh/forgot",
    component: _7de86adc,
    name: "forgot___zh"
  }, {
    path: "/zh/forgot-password",
    component: _8b5de4b4,
    name: "forgot-password___zh"
  }, {
    path: "/zh/groups",
    component: _237a825f,
    name: "groups___zh"
  }, {
    path: "/zh/HBO-signup",
    component: _28d828bf,
    name: "HBO-signup___zh"
  }, {
    path: "/zh/keto",
    component: _5f60827e,
    name: "keto___zh"
  }, {
    path: "/zh/login",
    component: _035ea04a,
    name: "login___zh"
  }, {
    path: "/zh/regional",
    component: _5d74baf4,
    name: "regional___zh"
  }, {
    path: "/zh/reset",
    component: _3b0d29a0,
    name: "reset___zh"
  }, {
    path: "/zh/schedule",
    component: _ebc13374,
    name: "schedule___zh"
  }, {
    path: "/zh/search",
    component: _ae30fad2,
    name: "search___zh"
  }, {
    path: "/zh/settings",
    component: _b265cb20,
    name: "settings___zh"
  }, {
    path: "/zh/signup",
    component: _966153f2,
    name: "signup___zh"
  }, {
    path: "/zh/update-required",
    component: _48da3678,
    name: "update-required___zh"
  }, {
    path: "/zh/users",
    component: _1185f60e,
    name: "users___zh"
  }, {
    path: "/de/academy/material",
    component: _4a2e49e4,
    name: "academy-material___de"
  }, {
    path: "/de/fitness/groups",
    component: _75df5685,
    name: "fitness-groups___de"
  }, {
    path: "/de/groups/deleted",
    component: _49c33634,
    name: "groups-deleted___de"
  }, {
    path: "/de/keto/groups",
    component: _e448e6ac,
    name: "keto-groups___de"
  }, {
    path: "/de/regional/groups",
    component: _f07a21c0,
    name: "regional-groups___de"
  }, {
    path: "/de/settings/documents",
    component: _46146e56,
    name: "settings-documents___de"
  }, {
    path: "/de/settings/licenses",
    component: _26780024,
    name: "settings-licenses___de"
  }, {
    path: "/el/academy/material",
    component: _4a2e49e4,
    name: "academy-material___el"
  }, {
    path: "/el/fitness/groups",
    component: _75df5685,
    name: "fitness-groups___el"
  }, {
    path: "/el/groups/deleted",
    component: _49c33634,
    name: "groups-deleted___el"
  }, {
    path: "/el/keto/groups",
    component: _e448e6ac,
    name: "keto-groups___el"
  }, {
    path: "/el/regional/groups",
    component: _f07a21c0,
    name: "regional-groups___el"
  }, {
    path: "/el/settings/documents",
    component: _46146e56,
    name: "settings-documents___el"
  }, {
    path: "/el/settings/licenses",
    component: _26780024,
    name: "settings-licenses___el"
  }, {
    path: "/en-gb/academy/material",
    component: _4a2e49e4,
    name: "academy-material___en-gb"
  }, {
    path: "/en-gb/fitness/groups",
    component: _75df5685,
    name: "fitness-groups___en-gb"
  }, {
    path: "/en-gb/groups/deleted",
    component: _49c33634,
    name: "groups-deleted___en-gb"
  }, {
    path: "/en-gb/keto/groups",
    component: _e448e6ac,
    name: "keto-groups___en-gb"
  }, {
    path: "/en-gb/regional/groups",
    component: _f07a21c0,
    name: "regional-groups___en-gb"
  }, {
    path: "/en-gb/settings/documents",
    component: _46146e56,
    name: "settings-documents___en-gb"
  }, {
    path: "/en-gb/settings/licenses",
    component: _26780024,
    name: "settings-licenses___en-gb"
  }, {
    path: "/es/academy/material",
    component: _4a2e49e4,
    name: "academy-material___es"
  }, {
    path: "/es/fitness/groups",
    component: _75df5685,
    name: "fitness-groups___es"
  }, {
    path: "/es/groups/deleted",
    component: _49c33634,
    name: "groups-deleted___es"
  }, {
    path: "/es/keto/groups",
    component: _e448e6ac,
    name: "keto-groups___es"
  }, {
    path: "/es/regional/groups",
    component: _f07a21c0,
    name: "regional-groups___es"
  }, {
    path: "/es/settings/documents",
    component: _46146e56,
    name: "settings-documents___es"
  }, {
    path: "/es/settings/licenses",
    component: _26780024,
    name: "settings-licenses___es"
  }, {
    path: "/fitness/groups/deleted",
    component: _279ec78c,
    name: "fitness-groups-deleted___en"
  }, {
    path: "/fr/academy/material",
    component: _4a2e49e4,
    name: "academy-material___fr"
  }, {
    path: "/fr/fitness/groups",
    component: _75df5685,
    name: "fitness-groups___fr"
  }, {
    path: "/fr/groups/deleted",
    component: _49c33634,
    name: "groups-deleted___fr"
  }, {
    path: "/fr/keto/groups",
    component: _e448e6ac,
    name: "keto-groups___fr"
  }, {
    path: "/fr/regional/groups",
    component: _f07a21c0,
    name: "regional-groups___fr"
  }, {
    path: "/fr/settings/documents",
    component: _46146e56,
    name: "settings-documents___fr"
  }, {
    path: "/fr/settings/licenses",
    component: _26780024,
    name: "settings-licenses___fr"
  }, {
    path: "/it/academy/material",
    component: _4a2e49e4,
    name: "academy-material___it"
  }, {
    path: "/it/fitness/groups",
    component: _75df5685,
    name: "fitness-groups___it"
  }, {
    path: "/it/groups/deleted",
    component: _49c33634,
    name: "groups-deleted___it"
  }, {
    path: "/it/keto/groups",
    component: _e448e6ac,
    name: "keto-groups___it"
  }, {
    path: "/it/regional/groups",
    component: _f07a21c0,
    name: "regional-groups___it"
  }, {
    path: "/it/settings/documents",
    component: _46146e56,
    name: "settings-documents___it"
  }, {
    path: "/it/settings/licenses",
    component: _26780024,
    name: "settings-licenses___it"
  }, {
    path: "/keto/groups/deleted",
    component: _31660871,
    name: "keto-groups-deleted___en"
  }, {
    path: "/nl-be/academy/material",
    component: _4a2e49e4,
    name: "academy-material___nl-be"
  }, {
    path: "/nl-be/fitness/groups",
    component: _75df5685,
    name: "fitness-groups___nl-be"
  }, {
    path: "/nl-be/groups/deleted",
    component: _49c33634,
    name: "groups-deleted___nl-be"
  }, {
    path: "/nl-be/keto/groups",
    component: _e448e6ac,
    name: "keto-groups___nl-be"
  }, {
    path: "/nl-be/regional/groups",
    component: _f07a21c0,
    name: "regional-groups___nl-be"
  }, {
    path: "/nl-be/settings/documents",
    component: _46146e56,
    name: "settings-documents___nl-be"
  }, {
    path: "/nl-be/settings/licenses",
    component: _26780024,
    name: "settings-licenses___nl-be"
  }, {
    path: "/nl/academy/material",
    component: _4a2e49e4,
    name: "academy-material___nl"
  }, {
    path: "/nl/fitness/groups",
    component: _75df5685,
    name: "fitness-groups___nl"
  }, {
    path: "/nl/groups/deleted",
    component: _49c33634,
    name: "groups-deleted___nl"
  }, {
    path: "/nl/keto/groups",
    component: _e448e6ac,
    name: "keto-groups___nl"
  }, {
    path: "/nl/regional/groups",
    component: _f07a21c0,
    name: "regional-groups___nl"
  }, {
    path: "/nl/settings/documents",
    component: _46146e56,
    name: "settings-documents___nl"
  }, {
    path: "/nl/settings/licenses",
    component: _26780024,
    name: "settings-licenses___nl"
  }, {
    path: "/pl/academy/material",
    component: _4a2e49e4,
    name: "academy-material___pl"
  }, {
    path: "/pl/fitness/groups",
    component: _75df5685,
    name: "fitness-groups___pl"
  }, {
    path: "/pl/groups/deleted",
    component: _49c33634,
    name: "groups-deleted___pl"
  }, {
    path: "/pl/keto/groups",
    component: _e448e6ac,
    name: "keto-groups___pl"
  }, {
    path: "/pl/regional/groups",
    component: _f07a21c0,
    name: "regional-groups___pl"
  }, {
    path: "/pl/settings/documents",
    component: _46146e56,
    name: "settings-documents___pl"
  }, {
    path: "/pl/settings/licenses",
    component: _26780024,
    name: "settings-licenses___pl"
  }, {
    path: "/regional/groups/deleted",
    component: _4efea567,
    name: "regional-groups-deleted___en"
  }, {
    path: "/zh/academy/material",
    component: _4a2e49e4,
    name: "academy-material___zh"
  }, {
    path: "/zh/fitness/groups",
    component: _75df5685,
    name: "fitness-groups___zh"
  }, {
    path: "/zh/groups/deleted",
    component: _49c33634,
    name: "groups-deleted___zh"
  }, {
    path: "/zh/keto/groups",
    component: _e448e6ac,
    name: "keto-groups___zh"
  }, {
    path: "/zh/regional/groups",
    component: _f07a21c0,
    name: "regional-groups___zh"
  }, {
    path: "/zh/settings/documents",
    component: _46146e56,
    name: "settings-documents___zh"
  }, {
    path: "/zh/settings/licenses",
    component: _26780024,
    name: "settings-licenses___zh"
  }, {
    path: "/de/fitness/groups/deleted",
    component: _279ec78c,
    name: "fitness-groups-deleted___de"
  }, {
    path: "/de/keto/groups/deleted",
    component: _31660871,
    name: "keto-groups-deleted___de"
  }, {
    path: "/de/regional/groups/deleted",
    component: _4efea567,
    name: "regional-groups-deleted___de"
  }, {
    path: "/el/fitness/groups/deleted",
    component: _279ec78c,
    name: "fitness-groups-deleted___el"
  }, {
    path: "/el/keto/groups/deleted",
    component: _31660871,
    name: "keto-groups-deleted___el"
  }, {
    path: "/el/regional/groups/deleted",
    component: _4efea567,
    name: "regional-groups-deleted___el"
  }, {
    path: "/en-gb/fitness/groups/deleted",
    component: _279ec78c,
    name: "fitness-groups-deleted___en-gb"
  }, {
    path: "/en-gb/keto/groups/deleted",
    component: _31660871,
    name: "keto-groups-deleted___en-gb"
  }, {
    path: "/en-gb/regional/groups/deleted",
    component: _4efea567,
    name: "regional-groups-deleted___en-gb"
  }, {
    path: "/es/fitness/groups/deleted",
    component: _279ec78c,
    name: "fitness-groups-deleted___es"
  }, {
    path: "/es/keto/groups/deleted",
    component: _31660871,
    name: "keto-groups-deleted___es"
  }, {
    path: "/es/regional/groups/deleted",
    component: _4efea567,
    name: "regional-groups-deleted___es"
  }, {
    path: "/fr/fitness/groups/deleted",
    component: _279ec78c,
    name: "fitness-groups-deleted___fr"
  }, {
    path: "/fr/keto/groups/deleted",
    component: _31660871,
    name: "keto-groups-deleted___fr"
  }, {
    path: "/fr/regional/groups/deleted",
    component: _4efea567,
    name: "regional-groups-deleted___fr"
  }, {
    path: "/it/fitness/groups/deleted",
    component: _279ec78c,
    name: "fitness-groups-deleted___it"
  }, {
    path: "/it/keto/groups/deleted",
    component: _31660871,
    name: "keto-groups-deleted___it"
  }, {
    path: "/it/regional/groups/deleted",
    component: _4efea567,
    name: "regional-groups-deleted___it"
  }, {
    path: "/nl-be/fitness/groups/deleted",
    component: _279ec78c,
    name: "fitness-groups-deleted___nl-be"
  }, {
    path: "/nl-be/keto/groups/deleted",
    component: _31660871,
    name: "keto-groups-deleted___nl-be"
  }, {
    path: "/nl-be/regional/groups/deleted",
    component: _4efea567,
    name: "regional-groups-deleted___nl-be"
  }, {
    path: "/nl/fitness/groups/deleted",
    component: _279ec78c,
    name: "fitness-groups-deleted___nl"
  }, {
    path: "/nl/keto/groups/deleted",
    component: _31660871,
    name: "keto-groups-deleted___nl"
  }, {
    path: "/nl/regional/groups/deleted",
    component: _4efea567,
    name: "regional-groups-deleted___nl"
  }, {
    path: "/pl/fitness/groups/deleted",
    component: _279ec78c,
    name: "fitness-groups-deleted___pl"
  }, {
    path: "/pl/keto/groups/deleted",
    component: _31660871,
    name: "keto-groups-deleted___pl"
  }, {
    path: "/pl/regional/groups/deleted",
    component: _4efea567,
    name: "regional-groups-deleted___pl"
  }, {
    path: "/zh/fitness/groups/deleted",
    component: _279ec78c,
    name: "fitness-groups-deleted___zh"
  }, {
    path: "/zh/keto/groups/deleted",
    component: _31660871,
    name: "keto-groups-deleted___zh"
  }, {
    path: "/zh/regional/groups/deleted",
    component: _4efea567,
    name: "regional-groups-deleted___zh"
  }, {
    path: "/de/biva/regional/:sessionId?",
    component: _559c2ddc,
    name: "biva-regional-sessionId___de"
  }, {
    path: "/de/biva/sessions/:sessionId?",
    component: _2d5eb790,
    name: "biva-sessions-sessionId___de"
  }, {
    path: "/de/fitness/groups/:groupId",
    component: _06439faa,
    name: "fitness-groups-groupId___de"
  }, {
    path: "/de/keto/groups/:groupId",
    component: _16022b8f,
    name: "keto-groups-groupId___de"
  }, {
    path: "/de/regional/groups/:groupId",
    component: _49017285,
    name: "regional-groups-groupId___de"
  }, {
    path: "/el/biva/regional/:sessionId?",
    component: _559c2ddc,
    name: "biva-regional-sessionId___el"
  }, {
    path: "/el/biva/sessions/:sessionId?",
    component: _2d5eb790,
    name: "biva-sessions-sessionId___el"
  }, {
    path: "/el/fitness/groups/:groupId",
    component: _06439faa,
    name: "fitness-groups-groupId___el"
  }, {
    path: "/el/keto/groups/:groupId",
    component: _16022b8f,
    name: "keto-groups-groupId___el"
  }, {
    path: "/el/regional/groups/:groupId",
    component: _49017285,
    name: "regional-groups-groupId___el"
  }, {
    path: "/en-gb/biva/regional/:sessionId?",
    component: _559c2ddc,
    name: "biva-regional-sessionId___en-gb"
  }, {
    path: "/en-gb/biva/sessions/:sessionId?",
    component: _2d5eb790,
    name: "biva-sessions-sessionId___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId",
    component: _06439faa,
    name: "fitness-groups-groupId___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId",
    component: _16022b8f,
    name: "keto-groups-groupId___en-gb"
  }, {
    path: "/en-gb/regional/groups/:groupId",
    component: _49017285,
    name: "regional-groups-groupId___en-gb"
  }, {
    path: "/es/biva/regional/:sessionId?",
    component: _559c2ddc,
    name: "biva-regional-sessionId___es"
  }, {
    path: "/es/biva/sessions/:sessionId?",
    component: _2d5eb790,
    name: "biva-sessions-sessionId___es"
  }, {
    path: "/es/fitness/groups/:groupId",
    component: _06439faa,
    name: "fitness-groups-groupId___es"
  }, {
    path: "/es/keto/groups/:groupId",
    component: _16022b8f,
    name: "keto-groups-groupId___es"
  }, {
    path: "/es/regional/groups/:groupId",
    component: _49017285,
    name: "regional-groups-groupId___es"
  }, {
    path: "/fr/biva/regional/:sessionId?",
    component: _559c2ddc,
    name: "biva-regional-sessionId___fr"
  }, {
    path: "/fr/biva/sessions/:sessionId?",
    component: _2d5eb790,
    name: "biva-sessions-sessionId___fr"
  }, {
    path: "/fr/fitness/groups/:groupId",
    component: _06439faa,
    name: "fitness-groups-groupId___fr"
  }, {
    path: "/fr/keto/groups/:groupId",
    component: _16022b8f,
    name: "keto-groups-groupId___fr"
  }, {
    path: "/fr/regional/groups/:groupId",
    component: _49017285,
    name: "regional-groups-groupId___fr"
  }, {
    path: "/it/biva/regional/:sessionId?",
    component: _559c2ddc,
    name: "biva-regional-sessionId___it"
  }, {
    path: "/it/biva/sessions/:sessionId?",
    component: _2d5eb790,
    name: "biva-sessions-sessionId___it"
  }, {
    path: "/it/fitness/groups/:groupId",
    component: _06439faa,
    name: "fitness-groups-groupId___it"
  }, {
    path: "/it/keto/groups/:groupId",
    component: _16022b8f,
    name: "keto-groups-groupId___it"
  }, {
    path: "/it/regional/groups/:groupId",
    component: _49017285,
    name: "regional-groups-groupId___it"
  }, {
    path: "/nl-be/biva/regional/:sessionId?",
    component: _559c2ddc,
    name: "biva-regional-sessionId___nl-be"
  }, {
    path: "/nl-be/biva/sessions/:sessionId?",
    component: _2d5eb790,
    name: "biva-sessions-sessionId___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId",
    component: _06439faa,
    name: "fitness-groups-groupId___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId",
    component: _16022b8f,
    name: "keto-groups-groupId___nl-be"
  }, {
    path: "/nl-be/regional/groups/:groupId",
    component: _49017285,
    name: "regional-groups-groupId___nl-be"
  }, {
    path: "/nl/biva/regional/:sessionId?",
    component: _559c2ddc,
    name: "biva-regional-sessionId___nl"
  }, {
    path: "/nl/biva/sessions/:sessionId?",
    component: _2d5eb790,
    name: "biva-sessions-sessionId___nl"
  }, {
    path: "/nl/fitness/groups/:groupId",
    component: _06439faa,
    name: "fitness-groups-groupId___nl"
  }, {
    path: "/nl/keto/groups/:groupId",
    component: _16022b8f,
    name: "keto-groups-groupId___nl"
  }, {
    path: "/nl/regional/groups/:groupId",
    component: _49017285,
    name: "regional-groups-groupId___nl"
  }, {
    path: "/pl/biva/regional/:sessionId?",
    component: _559c2ddc,
    name: "biva-regional-sessionId___pl"
  }, {
    path: "/pl/biva/sessions/:sessionId?",
    component: _2d5eb790,
    name: "biva-sessions-sessionId___pl"
  }, {
    path: "/pl/fitness/groups/:groupId",
    component: _06439faa,
    name: "fitness-groups-groupId___pl"
  }, {
    path: "/pl/keto/groups/:groupId",
    component: _16022b8f,
    name: "keto-groups-groupId___pl"
  }, {
    path: "/pl/regional/groups/:groupId",
    component: _49017285,
    name: "regional-groups-groupId___pl"
  }, {
    path: "/zh/biva/regional/:sessionId?",
    component: _559c2ddc,
    name: "biva-regional-sessionId___zh"
  }, {
    path: "/zh/biva/sessions/:sessionId?",
    component: _2d5eb790,
    name: "biva-sessions-sessionId___zh"
  }, {
    path: "/zh/fitness/groups/:groupId",
    component: _06439faa,
    name: "fitness-groups-groupId___zh"
  }, {
    path: "/zh/keto/groups/:groupId",
    component: _16022b8f,
    name: "keto-groups-groupId___zh"
  }, {
    path: "/zh/regional/groups/:groupId",
    component: _49017285,
    name: "regional-groups-groupId___zh"
  }, {
    path: "/de/fitness/groups/:groupId/deleted",
    component: _30155b71,
    name: "fitness-groups-groupId-deleted___de"
  }, {
    path: "/de/keto/groups/:groupId/deleted",
    component: _4a608216,
    name: "keto-groups-groupId-deleted___de"
  }, {
    path: "/de/regional/groups/:groupId/deleted",
    component: _8aac38e8,
    name: "regional-groups-groupId-deleted___de"
  }, {
    path: "/el/fitness/groups/:groupId/deleted",
    component: _30155b71,
    name: "fitness-groups-groupId-deleted___el"
  }, {
    path: "/el/keto/groups/:groupId/deleted",
    component: _4a608216,
    name: "keto-groups-groupId-deleted___el"
  }, {
    path: "/el/regional/groups/:groupId/deleted",
    component: _8aac38e8,
    name: "regional-groups-groupId-deleted___el"
  }, {
    path: "/en-gb/fitness/groups/:groupId/deleted",
    component: _30155b71,
    name: "fitness-groups-groupId-deleted___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/deleted",
    component: _4a608216,
    name: "keto-groups-groupId-deleted___en-gb"
  }, {
    path: "/en-gb/regional/groups/:groupId/deleted",
    component: _8aac38e8,
    name: "regional-groups-groupId-deleted___en-gb"
  }, {
    path: "/es/fitness/groups/:groupId/deleted",
    component: _30155b71,
    name: "fitness-groups-groupId-deleted___es"
  }, {
    path: "/es/keto/groups/:groupId/deleted",
    component: _4a608216,
    name: "keto-groups-groupId-deleted___es"
  }, {
    path: "/es/regional/groups/:groupId/deleted",
    component: _8aac38e8,
    name: "regional-groups-groupId-deleted___es"
  }, {
    path: "/fr/fitness/groups/:groupId/deleted",
    component: _30155b71,
    name: "fitness-groups-groupId-deleted___fr"
  }, {
    path: "/fr/keto/groups/:groupId/deleted",
    component: _4a608216,
    name: "keto-groups-groupId-deleted___fr"
  }, {
    path: "/fr/regional/groups/:groupId/deleted",
    component: _8aac38e8,
    name: "regional-groups-groupId-deleted___fr"
  }, {
    path: "/it/fitness/groups/:groupId/deleted",
    component: _30155b71,
    name: "fitness-groups-groupId-deleted___it"
  }, {
    path: "/it/keto/groups/:groupId/deleted",
    component: _4a608216,
    name: "keto-groups-groupId-deleted___it"
  }, {
    path: "/it/regional/groups/:groupId/deleted",
    component: _8aac38e8,
    name: "regional-groups-groupId-deleted___it"
  }, {
    path: "/nl-be/fitness/groups/:groupId/deleted",
    component: _30155b71,
    name: "fitness-groups-groupId-deleted___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/deleted",
    component: _4a608216,
    name: "keto-groups-groupId-deleted___nl-be"
  }, {
    path: "/nl-be/regional/groups/:groupId/deleted",
    component: _8aac38e8,
    name: "regional-groups-groupId-deleted___nl-be"
  }, {
    path: "/nl/fitness/groups/:groupId/deleted",
    component: _30155b71,
    name: "fitness-groups-groupId-deleted___nl"
  }, {
    path: "/nl/keto/groups/:groupId/deleted",
    component: _4a608216,
    name: "keto-groups-groupId-deleted___nl"
  }, {
    path: "/nl/regional/groups/:groupId/deleted",
    component: _8aac38e8,
    name: "regional-groups-groupId-deleted___nl"
  }, {
    path: "/pl/fitness/groups/:groupId/deleted",
    component: _30155b71,
    name: "fitness-groups-groupId-deleted___pl"
  }, {
    path: "/pl/keto/groups/:groupId/deleted",
    component: _4a608216,
    name: "keto-groups-groupId-deleted___pl"
  }, {
    path: "/pl/regional/groups/:groupId/deleted",
    component: _8aac38e8,
    name: "regional-groups-groupId-deleted___pl"
  }, {
    path: "/zh/fitness/groups/:groupId/deleted",
    component: _30155b71,
    name: "fitness-groups-groupId-deleted___zh"
  }, {
    path: "/zh/keto/groups/:groupId/deleted",
    component: _4a608216,
    name: "keto-groups-groupId-deleted___zh"
  }, {
    path: "/zh/regional/groups/:groupId/deleted",
    component: _8aac38e8,
    name: "regional-groups-groupId-deleted___zh"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?",
    component: _94cf728a,
    name: "fitness-groups-groupId-patients-patientId___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?",
    component: _e12aae40,
    name: "keto-groups-groupId-patients-patientId___de"
  }, {
    path: "/de/regional/groups/:groupId/patients/:patientId?",
    component: _768a9954,
    name: "regional-groups-groupId-patients-patientId___de"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?",
    component: _94cf728a,
    name: "fitness-groups-groupId-patients-patientId___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?",
    component: _e12aae40,
    name: "keto-groups-groupId-patients-patientId___el"
  }, {
    path: "/el/regional/groups/:groupId/patients/:patientId?",
    component: _768a9954,
    name: "regional-groups-groupId-patients-patientId___el"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?",
    component: _94cf728a,
    name: "fitness-groups-groupId-patients-patientId___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?",
    component: _e12aae40,
    name: "keto-groups-groupId-patients-patientId___en-gb"
  }, {
    path: "/en-gb/regional/groups/:groupId/patients/:patientId?",
    component: _768a9954,
    name: "regional-groups-groupId-patients-patientId___en-gb"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?",
    component: _94cf728a,
    name: "fitness-groups-groupId-patients-patientId___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?",
    component: _e12aae40,
    name: "keto-groups-groupId-patients-patientId___es"
  }, {
    path: "/es/regional/groups/:groupId/patients/:patientId?",
    component: _768a9954,
    name: "regional-groups-groupId-patients-patientId___es"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?",
    component: _94cf728a,
    name: "fitness-groups-groupId-patients-patientId___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?",
    component: _e12aae40,
    name: "keto-groups-groupId-patients-patientId___fr"
  }, {
    path: "/fr/regional/groups/:groupId/patients/:patientId?",
    component: _768a9954,
    name: "regional-groups-groupId-patients-patientId___fr"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?",
    component: _94cf728a,
    name: "fitness-groups-groupId-patients-patientId___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?",
    component: _e12aae40,
    name: "keto-groups-groupId-patients-patientId___it"
  }, {
    path: "/it/regional/groups/:groupId/patients/:patientId?",
    component: _768a9954,
    name: "regional-groups-groupId-patients-patientId___it"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?",
    component: _94cf728a,
    name: "fitness-groups-groupId-patients-patientId___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?",
    component: _e12aae40,
    name: "keto-groups-groupId-patients-patientId___nl-be"
  }, {
    path: "/nl-be/regional/groups/:groupId/patients/:patientId?",
    component: _768a9954,
    name: "regional-groups-groupId-patients-patientId___nl-be"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?",
    component: _94cf728a,
    name: "fitness-groups-groupId-patients-patientId___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?",
    component: _e12aae40,
    name: "keto-groups-groupId-patients-patientId___nl"
  }, {
    path: "/nl/regional/groups/:groupId/patients/:patientId?",
    component: _768a9954,
    name: "regional-groups-groupId-patients-patientId___nl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?",
    component: _94cf728a,
    name: "fitness-groups-groupId-patients-patientId___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?",
    component: _e12aae40,
    name: "keto-groups-groupId-patients-patientId___pl"
  }, {
    path: "/pl/regional/groups/:groupId/patients/:patientId?",
    component: _768a9954,
    name: "regional-groups-groupId-patients-patientId___pl"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?",
    component: _94cf728a,
    name: "fitness-groups-groupId-patients-patientId___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?",
    component: _e12aae40,
    name: "keto-groups-groupId-patients-patientId___zh"
  }, {
    path: "/zh/regional/groups/:groupId/patients/:patientId?",
    component: _768a9954,
    name: "regional-groups-groupId-patients-patientId___zh"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/somatotype",
    component: _5f12e78c,
    name: "fitness-groups-groupId-patients-patientId-somatotype___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/somatotype",
    component: _52911487,
    name: "keto-groups-groupId-patients-patientId-somatotype___de"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/somatotype",
    component: _5f12e78c,
    name: "fitness-groups-groupId-patients-patientId-somatotype___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/somatotype",
    component: _52911487,
    name: "keto-groups-groupId-patients-patientId-somatotype___el"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/somatotype",
    component: _5f12e78c,
    name: "fitness-groups-groupId-patients-patientId-somatotype___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/somatotype",
    component: _52911487,
    name: "keto-groups-groupId-patients-patientId-somatotype___en-gb"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/somatotype",
    component: _5f12e78c,
    name: "fitness-groups-groupId-patients-patientId-somatotype___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/somatotype",
    component: _52911487,
    name: "keto-groups-groupId-patients-patientId-somatotype___es"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/somatotype",
    component: _5f12e78c,
    name: "fitness-groups-groupId-patients-patientId-somatotype___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/somatotype",
    component: _52911487,
    name: "keto-groups-groupId-patients-patientId-somatotype___fr"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/somatotype",
    component: _5f12e78c,
    name: "fitness-groups-groupId-patients-patientId-somatotype___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/somatotype",
    component: _52911487,
    name: "keto-groups-groupId-patients-patientId-somatotype___it"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/somatotype",
    component: _5f12e78c,
    name: "fitness-groups-groupId-patients-patientId-somatotype___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/somatotype",
    component: _52911487,
    name: "keto-groups-groupId-patients-patientId-somatotype___nl-be"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/somatotype",
    component: _5f12e78c,
    name: "fitness-groups-groupId-patients-patientId-somatotype___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/somatotype",
    component: _52911487,
    name: "keto-groups-groupId-patients-patientId-somatotype___nl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/somatotype",
    component: _5f12e78c,
    name: "fitness-groups-groupId-patients-patientId-somatotype___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/somatotype",
    component: _52911487,
    name: "keto-groups-groupId-patients-patientId-somatotype___pl"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/somatotype",
    component: _5f12e78c,
    name: "fitness-groups-groupId-patients-patientId-somatotype___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/somatotype",
    component: _52911487,
    name: "keto-groups-groupId-patients-patientId-somatotype___zh"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _571b7c68,
    name: "fitness-groups-groupId-patients-patientId-exams-deleted___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _52cb7947,
    name: "keto-groups-groupId-patients-patientId-exams-deleted___de"
  }, {
    path: "/de/regional/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _e07e58de,
    name: "regional-groups-groupId-patients-patientId-exams-deleted___de"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _571b7c68,
    name: "fitness-groups-groupId-patients-patientId-exams-deleted___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _52cb7947,
    name: "keto-groups-groupId-patients-patientId-exams-deleted___el"
  }, {
    path: "/el/regional/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _e07e58de,
    name: "regional-groups-groupId-patients-patientId-exams-deleted___el"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _571b7c68,
    name: "fitness-groups-groupId-patients-patientId-exams-deleted___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _52cb7947,
    name: "keto-groups-groupId-patients-patientId-exams-deleted___en-gb"
  }, {
    path: "/en-gb/regional/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _e07e58de,
    name: "regional-groups-groupId-patients-patientId-exams-deleted___en-gb"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _571b7c68,
    name: "fitness-groups-groupId-patients-patientId-exams-deleted___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _52cb7947,
    name: "keto-groups-groupId-patients-patientId-exams-deleted___es"
  }, {
    path: "/es/regional/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _e07e58de,
    name: "regional-groups-groupId-patients-patientId-exams-deleted___es"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _571b7c68,
    name: "fitness-groups-groupId-patients-patientId-exams-deleted___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _52cb7947,
    name: "keto-groups-groupId-patients-patientId-exams-deleted___fr"
  }, {
    path: "/fr/regional/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _e07e58de,
    name: "regional-groups-groupId-patients-patientId-exams-deleted___fr"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _571b7c68,
    name: "fitness-groups-groupId-patients-patientId-exams-deleted___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _52cb7947,
    name: "keto-groups-groupId-patients-patientId-exams-deleted___it"
  }, {
    path: "/it/regional/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _e07e58de,
    name: "regional-groups-groupId-patients-patientId-exams-deleted___it"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _571b7c68,
    name: "fitness-groups-groupId-patients-patientId-exams-deleted___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _52cb7947,
    name: "keto-groups-groupId-patients-patientId-exams-deleted___nl-be"
  }, {
    path: "/nl-be/regional/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _e07e58de,
    name: "regional-groups-groupId-patients-patientId-exams-deleted___nl-be"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _571b7c68,
    name: "fitness-groups-groupId-patients-patientId-exams-deleted___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _52cb7947,
    name: "keto-groups-groupId-patients-patientId-exams-deleted___nl"
  }, {
    path: "/nl/regional/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _e07e58de,
    name: "regional-groups-groupId-patients-patientId-exams-deleted___nl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _571b7c68,
    name: "fitness-groups-groupId-patients-patientId-exams-deleted___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _52cb7947,
    name: "keto-groups-groupId-patients-patientId-exams-deleted___pl"
  }, {
    path: "/pl/regional/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _e07e58de,
    name: "regional-groups-groupId-patients-patientId-exams-deleted___pl"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _571b7c68,
    name: "fitness-groups-groupId-patients-patientId-exams-deleted___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _52cb7947,
    name: "keto-groups-groupId-patients-patientId-exams-deleted___zh"
  }, {
    path: "/zh/regional/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _e07e58de,
    name: "regional-groups-groupId-patients-patientId-exams-deleted___zh"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _ccdea1ee,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _b19a4242,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _aee71142,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _a2c262d4,
    name: "fitness-groups-groupId-patients-patientId-exams-examId___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _403ceaa6,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _95c71ed0,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _1274a9e0,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _513bb104,
    name: "keto-groups-groupId-patients-patientId-bia-biaId___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _7695389a,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _60b4a44c,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _53fa46bb,
    name: "keto-groups-groupId-patients-patientId-exams-examId___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _601461a8,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _5163f3bd,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _a287a5f6,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId___de"
  }, {
    path: "/de/regional/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _8b7ab59e,
    name: "regional-groups-groupId-patients-patientId-exams-examId___de"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _ccdea1ee,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _b19a4242,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _aee71142,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _a2c262d4,
    name: "fitness-groups-groupId-patients-patientId-exams-examId___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _403ceaa6,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _95c71ed0,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _1274a9e0,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _513bb104,
    name: "keto-groups-groupId-patients-patientId-bia-biaId___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _7695389a,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _60b4a44c,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _53fa46bb,
    name: "keto-groups-groupId-patients-patientId-exams-examId___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _601461a8,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _5163f3bd,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _a287a5f6,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId___el"
  }, {
    path: "/el/regional/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _8b7ab59e,
    name: "regional-groups-groupId-patients-patientId-exams-examId___el"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _ccdea1ee,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _b19a4242,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _aee71142,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _a2c262d4,
    name: "fitness-groups-groupId-patients-patientId-exams-examId___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _403ceaa6,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _95c71ed0,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _1274a9e0,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _513bb104,
    name: "keto-groups-groupId-patients-patientId-bia-biaId___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _7695389a,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _60b4a44c,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _53fa46bb,
    name: "keto-groups-groupId-patients-patientId-exams-examId___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _601461a8,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _5163f3bd,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _a287a5f6,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId___en-gb"
  }, {
    path: "/en-gb/regional/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _8b7ab59e,
    name: "regional-groups-groupId-patients-patientId-exams-examId___en-gb"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _ccdea1ee,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _b19a4242,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _aee71142,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _a2c262d4,
    name: "fitness-groups-groupId-patients-patientId-exams-examId___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _403ceaa6,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _95c71ed0,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _1274a9e0,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _513bb104,
    name: "keto-groups-groupId-patients-patientId-bia-biaId___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _7695389a,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _60b4a44c,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _53fa46bb,
    name: "keto-groups-groupId-patients-patientId-exams-examId___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _601461a8,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _5163f3bd,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _a287a5f6,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId___es"
  }, {
    path: "/es/regional/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _8b7ab59e,
    name: "regional-groups-groupId-patients-patientId-exams-examId___es"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _ccdea1ee,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _b19a4242,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _aee71142,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _a2c262d4,
    name: "fitness-groups-groupId-patients-patientId-exams-examId___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _403ceaa6,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _95c71ed0,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _1274a9e0,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _513bb104,
    name: "keto-groups-groupId-patients-patientId-bia-biaId___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _7695389a,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _60b4a44c,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _53fa46bb,
    name: "keto-groups-groupId-patients-patientId-exams-examId___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _601461a8,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _5163f3bd,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _a287a5f6,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId___fr"
  }, {
    path: "/fr/regional/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _8b7ab59e,
    name: "regional-groups-groupId-patients-patientId-exams-examId___fr"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _ccdea1ee,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _b19a4242,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _aee71142,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _a2c262d4,
    name: "fitness-groups-groupId-patients-patientId-exams-examId___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _403ceaa6,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _95c71ed0,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _1274a9e0,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _513bb104,
    name: "keto-groups-groupId-patients-patientId-bia-biaId___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _7695389a,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _60b4a44c,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _53fa46bb,
    name: "keto-groups-groupId-patients-patientId-exams-examId___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _601461a8,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _5163f3bd,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _a287a5f6,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId___it"
  }, {
    path: "/it/regional/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _8b7ab59e,
    name: "regional-groups-groupId-patients-patientId-exams-examId___it"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _ccdea1ee,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _b19a4242,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _aee71142,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _a2c262d4,
    name: "fitness-groups-groupId-patients-patientId-exams-examId___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _403ceaa6,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _95c71ed0,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _1274a9e0,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _513bb104,
    name: "keto-groups-groupId-patients-patientId-bia-biaId___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _7695389a,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _60b4a44c,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _53fa46bb,
    name: "keto-groups-groupId-patients-patientId-exams-examId___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _601461a8,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _5163f3bd,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _a287a5f6,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId___nl-be"
  }, {
    path: "/nl-be/regional/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _8b7ab59e,
    name: "regional-groups-groupId-patients-patientId-exams-examId___nl-be"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _ccdea1ee,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _b19a4242,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _aee71142,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _a2c262d4,
    name: "fitness-groups-groupId-patients-patientId-exams-examId___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _403ceaa6,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _95c71ed0,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _1274a9e0,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _513bb104,
    name: "keto-groups-groupId-patients-patientId-bia-biaId___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _7695389a,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _60b4a44c,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _53fa46bb,
    name: "keto-groups-groupId-patients-patientId-exams-examId___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _601461a8,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _5163f3bd,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _a287a5f6,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId___nl"
  }, {
    path: "/nl/regional/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _8b7ab59e,
    name: "regional-groups-groupId-patients-patientId-exams-examId___nl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _ccdea1ee,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _b19a4242,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _aee71142,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _a2c262d4,
    name: "fitness-groups-groupId-patients-patientId-exams-examId___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _403ceaa6,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _95c71ed0,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _1274a9e0,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _513bb104,
    name: "keto-groups-groupId-patients-patientId-bia-biaId___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _7695389a,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _60b4a44c,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _53fa46bb,
    name: "keto-groups-groupId-patients-patientId-exams-examId___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _601461a8,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _5163f3bd,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _a287a5f6,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId___pl"
  }, {
    path: "/pl/regional/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _8b7ab59e,
    name: "regional-groups-groupId-patients-patientId-exams-examId___pl"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _ccdea1ee,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _b19a4242,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _aee71142,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _a2c262d4,
    name: "fitness-groups-groupId-patients-patientId-exams-examId___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _403ceaa6,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _95c71ed0,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _1274a9e0,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _513bb104,
    name: "keto-groups-groupId-patients-patientId-bia-biaId___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _7695389a,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _60b4a44c,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _53fa46bb,
    name: "keto-groups-groupId-patients-patientId-exams-examId___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _601461a8,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _5163f3bd,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _a287a5f6,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId___zh"
  }, {
    path: "/zh/regional/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _8b7ab59e,
    name: "regional-groups-groupId-patients-patientId-exams-examId___zh"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _1ce94af1,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-bia___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _189a9e4b,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fitnessBia___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _7142b74d,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fluidRetention___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _139a907c,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-qualitative___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _b986ef20,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-quantitative___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _bf4ea3e8,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _02a4ca4d,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId-hr___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _dd45b0da,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId-radar___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _d3b82c84,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-bia___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _527c2aba,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _59b9a244,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fitnessBia___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _c5ca7340,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fluidRetention___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _5decf26f,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-functional___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _b7ba6414,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-hr___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _7d62b2d2,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-load___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _e6878ce2,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-qualitative___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _44397f7a,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-quantitative___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _679e85c2,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/skinfoldRadar",
    component: _2c31456c,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-skinfoldRadar___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _2483dede,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-somatotype___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _358fcb78,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-zscore___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _c781a690,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId-functional___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _c425be68,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _ddc17370,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId-load___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _69d28fac,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-bia___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _560ebda0,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fitnessBia___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _112a809c,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fluidRetention___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _74d5dd06,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-qualitative___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _7fb533d6,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-quantitative___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _2680b671,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _afff7b1c,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId-hr___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _8f1343e4,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId-radar___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _584db1ba,
    name: "keto-groups-groupId-patients-patientId-exams-examId-bia___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _ce50d2c4,
    name: "keto-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _063ca934,
    name: "keto-groups-groupId-patients-patientId-exams-examId-estimates___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _535605d9,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fitnessBia___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _ec78b04a,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fluidRetention___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _43c06d2c,
    name: "keto-groups-groupId-patients-patientId-exams-examId-functional___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _5bcdcff1,
    name: "keto-groups-groupId-patients-patientId-exams-examId-hr___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/ketone",
    component: _5e608c33,
    name: "keto-groups-groupId-patients-patientId-exams-examId-ketone___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _1355bbe6,
    name: "keto-groups-groupId-patients-patientId-exams-examId-load___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _12e441f4,
    name: "keto-groups-groupId-patients-patientId-exams-examId-qualitative___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _1cbc447e,
    name: "keto-groups-groupId-patients-patientId-exams-examId-quantitative___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _8e4cc2cc,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _6df0e78c,
    name: "keto-groups-groupId-patients-patientId-exams-examId-somatotype___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/weightLoss",
    component: _2e7ac522,
    name: "keto-groups-groupId-patients-patientId-exams-examId-weightLoss___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _2968b282,
    name: "keto-groups-groupId-patients-patientId-exams-examId-zscore___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _44b27a9d,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId-functional___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _3a34a3f1,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _600f6afa,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId-load___de"
  }, {
    path: "/de/regional/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _7a72ab19,
    name: "regional-groups-groupId-patients-patientId-exams-examId-bia___de"
  }, {
    path: "/de/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _74f524e4,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports___de"
  }, {
    path: "/de/regional/groups/:groupId/patients/:patientId?/exams/:examId?/vector",
    component: _65431074,
    name: "regional-groups-groupId-patients-patientId-exams-examId-vector___de"
  }, {
    path: "/de/regional/groups/:groupId/patients/:patientId?/histories/:historyId?/attachments",
    component: _198881a8,
    name: "regional-groups-groupId-patients-patientId-histories-historyId-attachments___de"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _1ce94af1,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-bia___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _189a9e4b,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fitnessBia___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _7142b74d,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fluidRetention___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _139a907c,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-qualitative___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _b986ef20,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-quantitative___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _bf4ea3e8,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _02a4ca4d,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId-hr___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _dd45b0da,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId-radar___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _d3b82c84,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-bia___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _527c2aba,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _59b9a244,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fitnessBia___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _c5ca7340,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fluidRetention___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _5decf26f,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-functional___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _b7ba6414,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-hr___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _7d62b2d2,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-load___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _e6878ce2,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-qualitative___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _44397f7a,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-quantitative___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _679e85c2,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/skinfoldRadar",
    component: _2c31456c,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-skinfoldRadar___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _2483dede,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-somatotype___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _358fcb78,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-zscore___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _c781a690,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId-functional___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _c425be68,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _ddc17370,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId-load___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _69d28fac,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-bia___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _560ebda0,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fitnessBia___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _112a809c,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fluidRetention___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _74d5dd06,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-qualitative___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _7fb533d6,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-quantitative___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _2680b671,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _afff7b1c,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId-hr___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _8f1343e4,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId-radar___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _584db1ba,
    name: "keto-groups-groupId-patients-patientId-exams-examId-bia___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _ce50d2c4,
    name: "keto-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _063ca934,
    name: "keto-groups-groupId-patients-patientId-exams-examId-estimates___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _535605d9,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fitnessBia___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _ec78b04a,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fluidRetention___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _43c06d2c,
    name: "keto-groups-groupId-patients-patientId-exams-examId-functional___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _5bcdcff1,
    name: "keto-groups-groupId-patients-patientId-exams-examId-hr___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?/ketone",
    component: _5e608c33,
    name: "keto-groups-groupId-patients-patientId-exams-examId-ketone___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _1355bbe6,
    name: "keto-groups-groupId-patients-patientId-exams-examId-load___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _12e441f4,
    name: "keto-groups-groupId-patients-patientId-exams-examId-qualitative___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _1cbc447e,
    name: "keto-groups-groupId-patients-patientId-exams-examId-quantitative___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _8e4cc2cc,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _6df0e78c,
    name: "keto-groups-groupId-patients-patientId-exams-examId-somatotype___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?/weightLoss",
    component: _2e7ac522,
    name: "keto-groups-groupId-patients-patientId-exams-examId-weightLoss___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _2968b282,
    name: "keto-groups-groupId-patients-patientId-exams-examId-zscore___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _44b27a9d,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId-functional___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _3a34a3f1,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _600f6afa,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId-load___el"
  }, {
    path: "/el/regional/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _7a72ab19,
    name: "regional-groups-groupId-patients-patientId-exams-examId-bia___el"
  }, {
    path: "/el/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _74f524e4,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports___el"
  }, {
    path: "/el/regional/groups/:groupId/patients/:patientId?/exams/:examId?/vector",
    component: _65431074,
    name: "regional-groups-groupId-patients-patientId-exams-examId-vector___el"
  }, {
    path: "/el/regional/groups/:groupId/patients/:patientId?/histories/:historyId?/attachments",
    component: _198881a8,
    name: "regional-groups-groupId-patients-patientId-histories-historyId-attachments___el"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _1ce94af1,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-bia___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _189a9e4b,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fitnessBia___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _7142b74d,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fluidRetention___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _139a907c,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-qualitative___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _b986ef20,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-quantitative___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _bf4ea3e8,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _02a4ca4d,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId-hr___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _dd45b0da,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId-radar___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _d3b82c84,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-bia___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _527c2aba,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _59b9a244,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fitnessBia___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _c5ca7340,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fluidRetention___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _5decf26f,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-functional___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _b7ba6414,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-hr___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _7d62b2d2,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-load___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _e6878ce2,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-qualitative___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _44397f7a,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-quantitative___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _679e85c2,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/skinfoldRadar",
    component: _2c31456c,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-skinfoldRadar___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _2483dede,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-somatotype___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _358fcb78,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-zscore___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _c781a690,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId-functional___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _c425be68,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _ddc17370,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId-load___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _69d28fac,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-bia___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _560ebda0,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fitnessBia___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _112a809c,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fluidRetention___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _74d5dd06,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-qualitative___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _7fb533d6,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-quantitative___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _2680b671,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _afff7b1c,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId-hr___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _8f1343e4,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId-radar___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _584db1ba,
    name: "keto-groups-groupId-patients-patientId-exams-examId-bia___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _ce50d2c4,
    name: "keto-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _063ca934,
    name: "keto-groups-groupId-patients-patientId-exams-examId-estimates___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _535605d9,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fitnessBia___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _ec78b04a,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fluidRetention___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _43c06d2c,
    name: "keto-groups-groupId-patients-patientId-exams-examId-functional___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _5bcdcff1,
    name: "keto-groups-groupId-patients-patientId-exams-examId-hr___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/ketone",
    component: _5e608c33,
    name: "keto-groups-groupId-patients-patientId-exams-examId-ketone___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _1355bbe6,
    name: "keto-groups-groupId-patients-patientId-exams-examId-load___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _12e441f4,
    name: "keto-groups-groupId-patients-patientId-exams-examId-qualitative___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _1cbc447e,
    name: "keto-groups-groupId-patients-patientId-exams-examId-quantitative___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _8e4cc2cc,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _6df0e78c,
    name: "keto-groups-groupId-patients-patientId-exams-examId-somatotype___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/weightLoss",
    component: _2e7ac522,
    name: "keto-groups-groupId-patients-patientId-exams-examId-weightLoss___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _2968b282,
    name: "keto-groups-groupId-patients-patientId-exams-examId-zscore___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _44b27a9d,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId-functional___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _3a34a3f1,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _600f6afa,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId-load___en-gb"
  }, {
    path: "/en-gb/regional/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _7a72ab19,
    name: "regional-groups-groupId-patients-patientId-exams-examId-bia___en-gb"
  }, {
    path: "/en-gb/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _74f524e4,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports___en-gb"
  }, {
    path: "/en-gb/regional/groups/:groupId/patients/:patientId?/exams/:examId?/vector",
    component: _65431074,
    name: "regional-groups-groupId-patients-patientId-exams-examId-vector___en-gb"
  }, {
    path: "/en-gb/regional/groups/:groupId/patients/:patientId?/histories/:historyId?/attachments",
    component: _198881a8,
    name: "regional-groups-groupId-patients-patientId-histories-historyId-attachments___en-gb"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _1ce94af1,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-bia___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _189a9e4b,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fitnessBia___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _7142b74d,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fluidRetention___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _139a907c,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-qualitative___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _b986ef20,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-quantitative___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _bf4ea3e8,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _02a4ca4d,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId-hr___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _dd45b0da,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId-radar___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _d3b82c84,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-bia___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _527c2aba,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _59b9a244,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fitnessBia___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _c5ca7340,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fluidRetention___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _5decf26f,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-functional___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _b7ba6414,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-hr___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _7d62b2d2,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-load___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _e6878ce2,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-qualitative___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _44397f7a,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-quantitative___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _679e85c2,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/skinfoldRadar",
    component: _2c31456c,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-skinfoldRadar___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _2483dede,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-somatotype___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _358fcb78,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-zscore___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _c781a690,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId-functional___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _c425be68,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _ddc17370,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId-load___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _69d28fac,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-bia___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _560ebda0,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fitnessBia___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _112a809c,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fluidRetention___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _74d5dd06,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-qualitative___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _7fb533d6,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-quantitative___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _2680b671,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _afff7b1c,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId-hr___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _8f1343e4,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId-radar___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _584db1ba,
    name: "keto-groups-groupId-patients-patientId-exams-examId-bia___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _ce50d2c4,
    name: "keto-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _063ca934,
    name: "keto-groups-groupId-patients-patientId-exams-examId-estimates___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _535605d9,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fitnessBia___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _ec78b04a,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fluidRetention___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _43c06d2c,
    name: "keto-groups-groupId-patients-patientId-exams-examId-functional___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _5bcdcff1,
    name: "keto-groups-groupId-patients-patientId-exams-examId-hr___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/ketone",
    component: _5e608c33,
    name: "keto-groups-groupId-patients-patientId-exams-examId-ketone___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _1355bbe6,
    name: "keto-groups-groupId-patients-patientId-exams-examId-load___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _12e441f4,
    name: "keto-groups-groupId-patients-patientId-exams-examId-qualitative___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _1cbc447e,
    name: "keto-groups-groupId-patients-patientId-exams-examId-quantitative___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _8e4cc2cc,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _6df0e78c,
    name: "keto-groups-groupId-patients-patientId-exams-examId-somatotype___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/weightLoss",
    component: _2e7ac522,
    name: "keto-groups-groupId-patients-patientId-exams-examId-weightLoss___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _2968b282,
    name: "keto-groups-groupId-patients-patientId-exams-examId-zscore___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _44b27a9d,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId-functional___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _3a34a3f1,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _600f6afa,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId-load___es"
  }, {
    path: "/es/regional/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _7a72ab19,
    name: "regional-groups-groupId-patients-patientId-exams-examId-bia___es"
  }, {
    path: "/es/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _74f524e4,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports___es"
  }, {
    path: "/es/regional/groups/:groupId/patients/:patientId?/exams/:examId?/vector",
    component: _65431074,
    name: "regional-groups-groupId-patients-patientId-exams-examId-vector___es"
  }, {
    path: "/es/regional/groups/:groupId/patients/:patientId?/histories/:historyId?/attachments",
    component: _198881a8,
    name: "regional-groups-groupId-patients-patientId-histories-historyId-attachments___es"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _1ce94af1,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-bia___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _189a9e4b,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fitnessBia___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _7142b74d,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fluidRetention___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _139a907c,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-qualitative___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _b986ef20,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-quantitative___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _bf4ea3e8,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _02a4ca4d,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId-hr___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _dd45b0da,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId-radar___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _d3b82c84,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-bia___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _527c2aba,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _59b9a244,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fitnessBia___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _c5ca7340,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fluidRetention___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _5decf26f,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-functional___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _b7ba6414,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-hr___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _7d62b2d2,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-load___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _e6878ce2,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-qualitative___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _44397f7a,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-quantitative___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _679e85c2,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/skinfoldRadar",
    component: _2c31456c,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-skinfoldRadar___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _2483dede,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-somatotype___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _358fcb78,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-zscore___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _c781a690,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId-functional___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _c425be68,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _ddc17370,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId-load___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _69d28fac,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-bia___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _560ebda0,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fitnessBia___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _112a809c,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fluidRetention___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _74d5dd06,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-qualitative___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _7fb533d6,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-quantitative___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _2680b671,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _afff7b1c,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId-hr___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _8f1343e4,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId-radar___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _584db1ba,
    name: "keto-groups-groupId-patients-patientId-exams-examId-bia___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _ce50d2c4,
    name: "keto-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _063ca934,
    name: "keto-groups-groupId-patients-patientId-exams-examId-estimates___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _535605d9,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fitnessBia___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _ec78b04a,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fluidRetention___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _43c06d2c,
    name: "keto-groups-groupId-patients-patientId-exams-examId-functional___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _5bcdcff1,
    name: "keto-groups-groupId-patients-patientId-exams-examId-hr___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/ketone",
    component: _5e608c33,
    name: "keto-groups-groupId-patients-patientId-exams-examId-ketone___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _1355bbe6,
    name: "keto-groups-groupId-patients-patientId-exams-examId-load___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _12e441f4,
    name: "keto-groups-groupId-patients-patientId-exams-examId-qualitative___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _1cbc447e,
    name: "keto-groups-groupId-patients-patientId-exams-examId-quantitative___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _8e4cc2cc,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _6df0e78c,
    name: "keto-groups-groupId-patients-patientId-exams-examId-somatotype___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/weightLoss",
    component: _2e7ac522,
    name: "keto-groups-groupId-patients-patientId-exams-examId-weightLoss___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _2968b282,
    name: "keto-groups-groupId-patients-patientId-exams-examId-zscore___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _44b27a9d,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId-functional___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _3a34a3f1,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _600f6afa,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId-load___fr"
  }, {
    path: "/fr/regional/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _7a72ab19,
    name: "regional-groups-groupId-patients-patientId-exams-examId-bia___fr"
  }, {
    path: "/fr/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _74f524e4,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports___fr"
  }, {
    path: "/fr/regional/groups/:groupId/patients/:patientId?/exams/:examId?/vector",
    component: _65431074,
    name: "regional-groups-groupId-patients-patientId-exams-examId-vector___fr"
  }, {
    path: "/fr/regional/groups/:groupId/patients/:patientId?/histories/:historyId?/attachments",
    component: _198881a8,
    name: "regional-groups-groupId-patients-patientId-histories-historyId-attachments___fr"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _1ce94af1,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-bia___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _189a9e4b,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fitnessBia___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _7142b74d,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fluidRetention___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _139a907c,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-qualitative___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _b986ef20,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-quantitative___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _bf4ea3e8,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _02a4ca4d,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId-hr___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _dd45b0da,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId-radar___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _d3b82c84,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-bia___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _527c2aba,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _59b9a244,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fitnessBia___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _c5ca7340,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fluidRetention___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _5decf26f,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-functional___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _b7ba6414,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-hr___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _7d62b2d2,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-load___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _e6878ce2,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-qualitative___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _44397f7a,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-quantitative___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _679e85c2,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/skinfoldRadar",
    component: _2c31456c,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-skinfoldRadar___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _2483dede,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-somatotype___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _358fcb78,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-zscore___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _c781a690,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId-functional___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _c425be68,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _ddc17370,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId-load___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _69d28fac,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-bia___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _560ebda0,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fitnessBia___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _112a809c,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fluidRetention___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _74d5dd06,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-qualitative___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _7fb533d6,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-quantitative___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _2680b671,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _afff7b1c,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId-hr___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _8f1343e4,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId-radar___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _584db1ba,
    name: "keto-groups-groupId-patients-patientId-exams-examId-bia___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _ce50d2c4,
    name: "keto-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _063ca934,
    name: "keto-groups-groupId-patients-patientId-exams-examId-estimates___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _535605d9,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fitnessBia___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _ec78b04a,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fluidRetention___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _43c06d2c,
    name: "keto-groups-groupId-patients-patientId-exams-examId-functional___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _5bcdcff1,
    name: "keto-groups-groupId-patients-patientId-exams-examId-hr___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/ketone",
    component: _5e608c33,
    name: "keto-groups-groupId-patients-patientId-exams-examId-ketone___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _1355bbe6,
    name: "keto-groups-groupId-patients-patientId-exams-examId-load___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _12e441f4,
    name: "keto-groups-groupId-patients-patientId-exams-examId-qualitative___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _1cbc447e,
    name: "keto-groups-groupId-patients-patientId-exams-examId-quantitative___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _8e4cc2cc,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _6df0e78c,
    name: "keto-groups-groupId-patients-patientId-exams-examId-somatotype___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/weightLoss",
    component: _2e7ac522,
    name: "keto-groups-groupId-patients-patientId-exams-examId-weightLoss___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _2968b282,
    name: "keto-groups-groupId-patients-patientId-exams-examId-zscore___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _44b27a9d,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId-functional___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _3a34a3f1,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _600f6afa,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId-load___it"
  }, {
    path: "/it/regional/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _7a72ab19,
    name: "regional-groups-groupId-patients-patientId-exams-examId-bia___it"
  }, {
    path: "/it/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _74f524e4,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports___it"
  }, {
    path: "/it/regional/groups/:groupId/patients/:patientId?/exams/:examId?/vector",
    component: _65431074,
    name: "regional-groups-groupId-patients-patientId-exams-examId-vector___it"
  }, {
    path: "/it/regional/groups/:groupId/patients/:patientId?/histories/:historyId?/attachments",
    component: _198881a8,
    name: "regional-groups-groupId-patients-patientId-histories-historyId-attachments___it"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _1ce94af1,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-bia___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _189a9e4b,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fitnessBia___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _7142b74d,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fluidRetention___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _139a907c,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-qualitative___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _b986ef20,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-quantitative___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _bf4ea3e8,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _02a4ca4d,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId-hr___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _dd45b0da,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId-radar___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _d3b82c84,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-bia___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _527c2aba,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _59b9a244,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fitnessBia___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _c5ca7340,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fluidRetention___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _5decf26f,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-functional___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _b7ba6414,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-hr___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _7d62b2d2,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-load___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _e6878ce2,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-qualitative___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _44397f7a,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-quantitative___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _679e85c2,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/skinfoldRadar",
    component: _2c31456c,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-skinfoldRadar___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _2483dede,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-somatotype___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _358fcb78,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-zscore___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _c781a690,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId-functional___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _c425be68,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _ddc17370,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId-load___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _69d28fac,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-bia___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _560ebda0,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fitnessBia___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _112a809c,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fluidRetention___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _74d5dd06,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-qualitative___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _7fb533d6,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-quantitative___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _2680b671,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _afff7b1c,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId-hr___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _8f1343e4,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId-radar___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _584db1ba,
    name: "keto-groups-groupId-patients-patientId-exams-examId-bia___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _ce50d2c4,
    name: "keto-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _063ca934,
    name: "keto-groups-groupId-patients-patientId-exams-examId-estimates___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _535605d9,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fitnessBia___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _ec78b04a,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fluidRetention___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _43c06d2c,
    name: "keto-groups-groupId-patients-patientId-exams-examId-functional___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _5bcdcff1,
    name: "keto-groups-groupId-patients-patientId-exams-examId-hr___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/ketone",
    component: _5e608c33,
    name: "keto-groups-groupId-patients-patientId-exams-examId-ketone___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _1355bbe6,
    name: "keto-groups-groupId-patients-patientId-exams-examId-load___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _12e441f4,
    name: "keto-groups-groupId-patients-patientId-exams-examId-qualitative___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _1cbc447e,
    name: "keto-groups-groupId-patients-patientId-exams-examId-quantitative___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _8e4cc2cc,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _6df0e78c,
    name: "keto-groups-groupId-patients-patientId-exams-examId-somatotype___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/weightLoss",
    component: _2e7ac522,
    name: "keto-groups-groupId-patients-patientId-exams-examId-weightLoss___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _2968b282,
    name: "keto-groups-groupId-patients-patientId-exams-examId-zscore___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _44b27a9d,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId-functional___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _3a34a3f1,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _600f6afa,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId-load___nl-be"
  }, {
    path: "/nl-be/regional/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _7a72ab19,
    name: "regional-groups-groupId-patients-patientId-exams-examId-bia___nl-be"
  }, {
    path: "/nl-be/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _74f524e4,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports___nl-be"
  }, {
    path: "/nl-be/regional/groups/:groupId/patients/:patientId?/exams/:examId?/vector",
    component: _65431074,
    name: "regional-groups-groupId-patients-patientId-exams-examId-vector___nl-be"
  }, {
    path: "/nl-be/regional/groups/:groupId/patients/:patientId?/histories/:historyId?/attachments",
    component: _198881a8,
    name: "regional-groups-groupId-patients-patientId-histories-historyId-attachments___nl-be"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _1ce94af1,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-bia___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _189a9e4b,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fitnessBia___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _7142b74d,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fluidRetention___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _139a907c,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-qualitative___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _b986ef20,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-quantitative___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _bf4ea3e8,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _02a4ca4d,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId-hr___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _dd45b0da,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId-radar___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _d3b82c84,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-bia___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _527c2aba,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _59b9a244,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fitnessBia___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _c5ca7340,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fluidRetention___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _5decf26f,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-functional___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _b7ba6414,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-hr___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _7d62b2d2,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-load___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _e6878ce2,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-qualitative___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _44397f7a,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-quantitative___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _679e85c2,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/skinfoldRadar",
    component: _2c31456c,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-skinfoldRadar___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _2483dede,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-somatotype___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _358fcb78,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-zscore___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _c781a690,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId-functional___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _c425be68,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _ddc17370,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId-load___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _69d28fac,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-bia___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _560ebda0,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fitnessBia___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _112a809c,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fluidRetention___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _74d5dd06,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-qualitative___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _7fb533d6,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-quantitative___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _2680b671,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _afff7b1c,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId-hr___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _8f1343e4,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId-radar___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _584db1ba,
    name: "keto-groups-groupId-patients-patientId-exams-examId-bia___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _ce50d2c4,
    name: "keto-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _063ca934,
    name: "keto-groups-groupId-patients-patientId-exams-examId-estimates___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _535605d9,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fitnessBia___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _ec78b04a,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fluidRetention___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _43c06d2c,
    name: "keto-groups-groupId-patients-patientId-exams-examId-functional___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _5bcdcff1,
    name: "keto-groups-groupId-patients-patientId-exams-examId-hr___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/ketone",
    component: _5e608c33,
    name: "keto-groups-groupId-patients-patientId-exams-examId-ketone___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _1355bbe6,
    name: "keto-groups-groupId-patients-patientId-exams-examId-load___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _12e441f4,
    name: "keto-groups-groupId-patients-patientId-exams-examId-qualitative___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _1cbc447e,
    name: "keto-groups-groupId-patients-patientId-exams-examId-quantitative___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _8e4cc2cc,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _6df0e78c,
    name: "keto-groups-groupId-patients-patientId-exams-examId-somatotype___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/weightLoss",
    component: _2e7ac522,
    name: "keto-groups-groupId-patients-patientId-exams-examId-weightLoss___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _2968b282,
    name: "keto-groups-groupId-patients-patientId-exams-examId-zscore___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _44b27a9d,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId-functional___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _3a34a3f1,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _600f6afa,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId-load___nl"
  }, {
    path: "/nl/regional/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _7a72ab19,
    name: "regional-groups-groupId-patients-patientId-exams-examId-bia___nl"
  }, {
    path: "/nl/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _74f524e4,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports___nl"
  }, {
    path: "/nl/regional/groups/:groupId/patients/:patientId?/exams/:examId?/vector",
    component: _65431074,
    name: "regional-groups-groupId-patients-patientId-exams-examId-vector___nl"
  }, {
    path: "/nl/regional/groups/:groupId/patients/:patientId?/histories/:historyId?/attachments",
    component: _198881a8,
    name: "regional-groups-groupId-patients-patientId-histories-historyId-attachments___nl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _1ce94af1,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-bia___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _189a9e4b,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fitnessBia___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _7142b74d,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fluidRetention___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _139a907c,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-qualitative___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _b986ef20,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-quantitative___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _bf4ea3e8,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _02a4ca4d,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId-hr___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _dd45b0da,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId-radar___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _d3b82c84,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-bia___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _527c2aba,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _59b9a244,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fitnessBia___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _c5ca7340,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fluidRetention___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _5decf26f,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-functional___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _b7ba6414,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-hr___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _7d62b2d2,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-load___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _e6878ce2,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-qualitative___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _44397f7a,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-quantitative___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _679e85c2,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/skinfoldRadar",
    component: _2c31456c,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-skinfoldRadar___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _2483dede,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-somatotype___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _358fcb78,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-zscore___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _c781a690,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId-functional___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _c425be68,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _ddc17370,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId-load___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _69d28fac,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-bia___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _560ebda0,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fitnessBia___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _112a809c,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fluidRetention___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _74d5dd06,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-qualitative___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _7fb533d6,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-quantitative___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _2680b671,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _afff7b1c,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId-hr___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _8f1343e4,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId-radar___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _584db1ba,
    name: "keto-groups-groupId-patients-patientId-exams-examId-bia___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _ce50d2c4,
    name: "keto-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _063ca934,
    name: "keto-groups-groupId-patients-patientId-exams-examId-estimates___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _535605d9,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fitnessBia___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _ec78b04a,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fluidRetention___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _43c06d2c,
    name: "keto-groups-groupId-patients-patientId-exams-examId-functional___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _5bcdcff1,
    name: "keto-groups-groupId-patients-patientId-exams-examId-hr___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/ketone",
    component: _5e608c33,
    name: "keto-groups-groupId-patients-patientId-exams-examId-ketone___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _1355bbe6,
    name: "keto-groups-groupId-patients-patientId-exams-examId-load___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _12e441f4,
    name: "keto-groups-groupId-patients-patientId-exams-examId-qualitative___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _1cbc447e,
    name: "keto-groups-groupId-patients-patientId-exams-examId-quantitative___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _8e4cc2cc,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _6df0e78c,
    name: "keto-groups-groupId-patients-patientId-exams-examId-somatotype___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/weightLoss",
    component: _2e7ac522,
    name: "keto-groups-groupId-patients-patientId-exams-examId-weightLoss___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _2968b282,
    name: "keto-groups-groupId-patients-patientId-exams-examId-zscore___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _44b27a9d,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId-functional___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _3a34a3f1,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _600f6afa,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId-load___pl"
  }, {
    path: "/pl/regional/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _7a72ab19,
    name: "regional-groups-groupId-patients-patientId-exams-examId-bia___pl"
  }, {
    path: "/pl/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _74f524e4,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports___pl"
  }, {
    path: "/pl/regional/groups/:groupId/patients/:patientId?/exams/:examId?/vector",
    component: _65431074,
    name: "regional-groups-groupId-patients-patientId-exams-examId-vector___pl"
  }, {
    path: "/pl/regional/groups/:groupId/patients/:patientId?/histories/:historyId?/attachments",
    component: _198881a8,
    name: "regional-groups-groupId-patients-patientId-histories-historyId-attachments___pl"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _1ce94af1,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-bia___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _189a9e4b,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fitnessBia___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _7142b74d,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fluidRetention___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _139a907c,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-qualitative___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _b986ef20,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-quantitative___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _bf4ea3e8,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _02a4ca4d,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId-hr___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _dd45b0da,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId-radar___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _d3b82c84,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-bia___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _527c2aba,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _59b9a244,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fitnessBia___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _c5ca7340,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fluidRetention___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _5decf26f,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-functional___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _b7ba6414,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-hr___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _7d62b2d2,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-load___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _e6878ce2,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-qualitative___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _44397f7a,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-quantitative___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _679e85c2,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/skinfoldRadar",
    component: _2c31456c,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-skinfoldRadar___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _2483dede,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-somatotype___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _358fcb78,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-zscore___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _c781a690,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId-functional___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _c425be68,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _ddc17370,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId-load___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _69d28fac,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-bia___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _560ebda0,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fitnessBia___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _112a809c,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fluidRetention___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _74d5dd06,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-qualitative___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _7fb533d6,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-quantitative___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _2680b671,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _afff7b1c,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId-hr___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _8f1343e4,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId-radar___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _584db1ba,
    name: "keto-groups-groupId-patients-patientId-exams-examId-bia___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _ce50d2c4,
    name: "keto-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _063ca934,
    name: "keto-groups-groupId-patients-patientId-exams-examId-estimates___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _535605d9,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fitnessBia___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _ec78b04a,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fluidRetention___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _43c06d2c,
    name: "keto-groups-groupId-patients-patientId-exams-examId-functional___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _5bcdcff1,
    name: "keto-groups-groupId-patients-patientId-exams-examId-hr___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/ketone",
    component: _5e608c33,
    name: "keto-groups-groupId-patients-patientId-exams-examId-ketone___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _1355bbe6,
    name: "keto-groups-groupId-patients-patientId-exams-examId-load___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _12e441f4,
    name: "keto-groups-groupId-patients-patientId-exams-examId-qualitative___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _1cbc447e,
    name: "keto-groups-groupId-patients-patientId-exams-examId-quantitative___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _8e4cc2cc,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _6df0e78c,
    name: "keto-groups-groupId-patients-patientId-exams-examId-somatotype___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/weightLoss",
    component: _2e7ac522,
    name: "keto-groups-groupId-patients-patientId-exams-examId-weightLoss___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _2968b282,
    name: "keto-groups-groupId-patients-patientId-exams-examId-zscore___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _44b27a9d,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId-functional___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _3a34a3f1,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _600f6afa,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId-load___zh"
  }, {
    path: "/zh/regional/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _7a72ab19,
    name: "regional-groups-groupId-patients-patientId-exams-examId-bia___zh"
  }, {
    path: "/zh/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _74f524e4,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports___zh"
  }, {
    path: "/zh/regional/groups/:groupId/patients/:patientId?/exams/:examId?/vector",
    component: _65431074,
    name: "regional-groups-groupId-patients-patientId-exams-examId-vector___zh"
  }, {
    path: "/zh/regional/groups/:groupId/patients/:patientId?/histories/:historyId?/attachments",
    component: _198881a8,
    name: "regional-groups-groupId-patients-patientId-histories-historyId-attachments___zh"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _2e8b57ac,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _7e289bf7,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _a3405036,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _53758b48,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___de"
  }, {
    path: "/de/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _3fafbc52,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___de"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _2e8b57ac,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _7e289bf7,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _a3405036,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _53758b48,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___el"
  }, {
    path: "/el/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _3fafbc52,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___el"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _2e8b57ac,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _7e289bf7,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _a3405036,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _53758b48,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___en-gb"
  }, {
    path: "/en-gb/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _3fafbc52,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___en-gb"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _2e8b57ac,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _7e289bf7,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _a3405036,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _53758b48,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___es"
  }, {
    path: "/es/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _3fafbc52,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___es"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _2e8b57ac,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _7e289bf7,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _a3405036,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _53758b48,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___fr"
  }, {
    path: "/fr/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _3fafbc52,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___fr"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _2e8b57ac,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _7e289bf7,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _a3405036,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _53758b48,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___it"
  }, {
    path: "/it/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _3fafbc52,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___it"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _2e8b57ac,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _7e289bf7,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _a3405036,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _53758b48,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___nl-be"
  }, {
    path: "/nl-be/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _3fafbc52,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___nl-be"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _2e8b57ac,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _7e289bf7,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _a3405036,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _53758b48,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___nl"
  }, {
    path: "/nl/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _3fafbc52,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___nl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _2e8b57ac,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _7e289bf7,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _a3405036,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _53758b48,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___pl"
  }, {
    path: "/pl/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _3fafbc52,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___pl"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _2e8b57ac,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _7e289bf7,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _a3405036,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _53758b48,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___zh"
  }, {
    path: "/zh/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _3fafbc52,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___zh"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _5745497c,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _6f9ffb4f,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _46503d21,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _1b17298a,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___de"
  }, {
    path: "/de/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _42063358,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___de"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _5745497c,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _6f9ffb4f,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _46503d21,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _1b17298a,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___el"
  }, {
    path: "/el/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _42063358,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___el"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _5745497c,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _6f9ffb4f,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _46503d21,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _1b17298a,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___en-gb"
  }, {
    path: "/en-gb/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _42063358,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___en-gb"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _5745497c,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _6f9ffb4f,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _46503d21,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _1b17298a,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___es"
  }, {
    path: "/es/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _42063358,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___es"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _5745497c,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _6f9ffb4f,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _46503d21,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _1b17298a,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___fr"
  }, {
    path: "/fr/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _42063358,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___fr"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _5745497c,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _6f9ffb4f,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _46503d21,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _1b17298a,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___it"
  }, {
    path: "/it/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _42063358,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___it"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _5745497c,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _6f9ffb4f,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _46503d21,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _1b17298a,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___nl-be"
  }, {
    path: "/nl-be/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _42063358,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___nl-be"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _5745497c,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _6f9ffb4f,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _46503d21,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _1b17298a,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___nl"
  }, {
    path: "/nl/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _42063358,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___nl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _5745497c,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _6f9ffb4f,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _46503d21,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _1b17298a,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___pl"
  }, {
    path: "/pl/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _42063358,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___pl"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _5745497c,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _6f9ffb4f,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _46503d21,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _1b17298a,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___zh"
  }, {
    path: "/zh/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _42063358,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___zh"
  }, {
    path: "/biva/regional/:sessionId?",
    component: _559c2ddc,
    name: "biva-regional-sessionId___en"
  }, {
    path: "/biva/sessions/:sessionId?",
    component: _2d5eb790,
    name: "biva-sessions-sessionId___en"
  }, {
    path: "/de/groups/:groupId",
    component: _3a3579f8,
    name: "groups-groupId___de"
  }, {
    path: "/el/groups/:groupId",
    component: _3a3579f8,
    name: "groups-groupId___el"
  }, {
    path: "/en-gb/groups/:groupId",
    component: _3a3579f8,
    name: "groups-groupId___en-gb"
  }, {
    path: "/es/groups/:groupId",
    component: _3a3579f8,
    name: "groups-groupId___es"
  }, {
    path: "/fitness/groups/:groupId",
    component: _06439faa,
    name: "fitness-groups-groupId___en"
  }, {
    path: "/fr/groups/:groupId",
    component: _3a3579f8,
    name: "groups-groupId___fr"
  }, {
    path: "/it/groups/:groupId",
    component: _3a3579f8,
    name: "groups-groupId___it"
  }, {
    path: "/keto/groups/:groupId",
    component: _16022b8f,
    name: "keto-groups-groupId___en"
  }, {
    path: "/nl-be/groups/:groupId",
    component: _3a3579f8,
    name: "groups-groupId___nl-be"
  }, {
    path: "/nl/groups/:groupId",
    component: _3a3579f8,
    name: "groups-groupId___nl"
  }, {
    path: "/pl/groups/:groupId",
    component: _3a3579f8,
    name: "groups-groupId___pl"
  }, {
    path: "/regional/groups/:groupId",
    component: _49017285,
    name: "regional-groups-groupId___en"
  }, {
    path: "/zh/groups/:groupId",
    component: _3a3579f8,
    name: "groups-groupId___zh"
  }, {
    path: "/de/groups/:groupId/bia",
    component: _1cb8a1ac,
    name: "groups-groupId-bia___de"
  }, {
    path: "/de/groups/:groupId/deleted",
    component: _6adb904b,
    name: "groups-groupId-deleted___de"
  }, {
    path: "/el/groups/:groupId/bia",
    component: _1cb8a1ac,
    name: "groups-groupId-bia___el"
  }, {
    path: "/el/groups/:groupId/deleted",
    component: _6adb904b,
    name: "groups-groupId-deleted___el"
  }, {
    path: "/en-gb/groups/:groupId/bia",
    component: _1cb8a1ac,
    name: "groups-groupId-bia___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/deleted",
    component: _6adb904b,
    name: "groups-groupId-deleted___en-gb"
  }, {
    path: "/es/groups/:groupId/bia",
    component: _1cb8a1ac,
    name: "groups-groupId-bia___es"
  }, {
    path: "/es/groups/:groupId/deleted",
    component: _6adb904b,
    name: "groups-groupId-deleted___es"
  }, {
    path: "/fitness/groups/:groupId/deleted",
    component: _30155b71,
    name: "fitness-groups-groupId-deleted___en"
  }, {
    path: "/fr/groups/:groupId/bia",
    component: _1cb8a1ac,
    name: "groups-groupId-bia___fr"
  }, {
    path: "/fr/groups/:groupId/deleted",
    component: _6adb904b,
    name: "groups-groupId-deleted___fr"
  }, {
    path: "/it/groups/:groupId/bia",
    component: _1cb8a1ac,
    name: "groups-groupId-bia___it"
  }, {
    path: "/it/groups/:groupId/deleted",
    component: _6adb904b,
    name: "groups-groupId-deleted___it"
  }, {
    path: "/keto/groups/:groupId/deleted",
    component: _4a608216,
    name: "keto-groups-groupId-deleted___en"
  }, {
    path: "/nl-be/groups/:groupId/bia",
    component: _1cb8a1ac,
    name: "groups-groupId-bia___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/deleted",
    component: _6adb904b,
    name: "groups-groupId-deleted___nl-be"
  }, {
    path: "/nl/groups/:groupId/bia",
    component: _1cb8a1ac,
    name: "groups-groupId-bia___nl"
  }, {
    path: "/nl/groups/:groupId/deleted",
    component: _6adb904b,
    name: "groups-groupId-deleted___nl"
  }, {
    path: "/pl/groups/:groupId/bia",
    component: _1cb8a1ac,
    name: "groups-groupId-bia___pl"
  }, {
    path: "/pl/groups/:groupId/deleted",
    component: _6adb904b,
    name: "groups-groupId-deleted___pl"
  }, {
    path: "/regional/groups/:groupId/deleted",
    component: _8aac38e8,
    name: "regional-groups-groupId-deleted___en"
  }, {
    path: "/zh/groups/:groupId/bia",
    component: _1cb8a1ac,
    name: "groups-groupId-bia___zh"
  }, {
    path: "/zh/groups/:groupId/deleted",
    component: _6adb904b,
    name: "groups-groupId-deleted___zh"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?",
    component: _c504ead6,
    name: "groups-groupId-patients-patientId___de"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?",
    component: _c504ead6,
    name: "groups-groupId-patients-patientId___el"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?",
    component: _c504ead6,
    name: "groups-groupId-patients-patientId___en-gb"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?",
    component: _c504ead6,
    name: "groups-groupId-patients-patientId___es"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?",
    component: _94cf728a,
    name: "fitness-groups-groupId-patients-patientId___en"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?",
    component: _c504ead6,
    name: "groups-groupId-patients-patientId___fr"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?",
    component: _c504ead6,
    name: "groups-groupId-patients-patientId___it"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?",
    component: _e12aae40,
    name: "keto-groups-groupId-patients-patientId___en"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?",
    component: _c504ead6,
    name: "groups-groupId-patients-patientId___nl-be"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?",
    component: _c504ead6,
    name: "groups-groupId-patients-patientId___nl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?",
    component: _c504ead6,
    name: "groups-groupId-patients-patientId___pl"
  }, {
    path: "/regional/groups/:groupId/patients/:patientId?",
    component: _768a9954,
    name: "regional-groups-groupId-patients-patientId___en"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?",
    component: _c504ead6,
    name: "groups-groupId-patients-patientId___zh"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/activities",
    component: _175ef6f0,
    name: "groups-groupId-patients-patientId-activities___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/bodyscan",
    component: _179c8654,
    name: "groups-groupId-patients-patientId-bodyscan___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/grid",
    component: _00c09153,
    name: "groups-groupId-patients-patientId-grid___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/schedule",
    component: _ee4374b8,
    name: "groups-groupId-patients-patientId-schedule___de"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/activities",
    component: _175ef6f0,
    name: "groups-groupId-patients-patientId-activities___el"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/bodyscan",
    component: _179c8654,
    name: "groups-groupId-patients-patientId-bodyscan___el"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/grid",
    component: _00c09153,
    name: "groups-groupId-patients-patientId-grid___el"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/schedule",
    component: _ee4374b8,
    name: "groups-groupId-patients-patientId-schedule___el"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/activities",
    component: _175ef6f0,
    name: "groups-groupId-patients-patientId-activities___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/bodyscan",
    component: _179c8654,
    name: "groups-groupId-patients-patientId-bodyscan___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/grid",
    component: _00c09153,
    name: "groups-groupId-patients-patientId-grid___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/schedule",
    component: _ee4374b8,
    name: "groups-groupId-patients-patientId-schedule___en-gb"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/activities",
    component: _175ef6f0,
    name: "groups-groupId-patients-patientId-activities___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/bodyscan",
    component: _179c8654,
    name: "groups-groupId-patients-patientId-bodyscan___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/grid",
    component: _00c09153,
    name: "groups-groupId-patients-patientId-grid___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/schedule",
    component: _ee4374b8,
    name: "groups-groupId-patients-patientId-schedule___es"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/somatotype",
    component: _5f12e78c,
    name: "fitness-groups-groupId-patients-patientId-somatotype___en"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/activities",
    component: _175ef6f0,
    name: "groups-groupId-patients-patientId-activities___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/bodyscan",
    component: _179c8654,
    name: "groups-groupId-patients-patientId-bodyscan___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/grid",
    component: _00c09153,
    name: "groups-groupId-patients-patientId-grid___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/schedule",
    component: _ee4374b8,
    name: "groups-groupId-patients-patientId-schedule___fr"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/activities",
    component: _175ef6f0,
    name: "groups-groupId-patients-patientId-activities___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/bodyscan",
    component: _179c8654,
    name: "groups-groupId-patients-patientId-bodyscan___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/grid",
    component: _00c09153,
    name: "groups-groupId-patients-patientId-grid___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/schedule",
    component: _ee4374b8,
    name: "groups-groupId-patients-patientId-schedule___it"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/somatotype",
    component: _52911487,
    name: "keto-groups-groupId-patients-patientId-somatotype___en"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/activities",
    component: _175ef6f0,
    name: "groups-groupId-patients-patientId-activities___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/bodyscan",
    component: _179c8654,
    name: "groups-groupId-patients-patientId-bodyscan___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/grid",
    component: _00c09153,
    name: "groups-groupId-patients-patientId-grid___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/schedule",
    component: _ee4374b8,
    name: "groups-groupId-patients-patientId-schedule___nl-be"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/activities",
    component: _175ef6f0,
    name: "groups-groupId-patients-patientId-activities___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/bodyscan",
    component: _179c8654,
    name: "groups-groupId-patients-patientId-bodyscan___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/grid",
    component: _00c09153,
    name: "groups-groupId-patients-patientId-grid___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/schedule",
    component: _ee4374b8,
    name: "groups-groupId-patients-patientId-schedule___nl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/activities",
    component: _175ef6f0,
    name: "groups-groupId-patients-patientId-activities___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/bodyscan",
    component: _179c8654,
    name: "groups-groupId-patients-patientId-bodyscan___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/grid",
    component: _00c09153,
    name: "groups-groupId-patients-patientId-grid___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/schedule",
    component: _ee4374b8,
    name: "groups-groupId-patients-patientId-schedule___pl"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/activities",
    component: _175ef6f0,
    name: "groups-groupId-patients-patientId-activities___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/bodyscan",
    component: _179c8654,
    name: "groups-groupId-patients-patientId-bodyscan___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/grid",
    component: _00c09153,
    name: "groups-groupId-patients-patientId-grid___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/schedule",
    component: _ee4374b8,
    name: "groups-groupId-patients-patientId-schedule___zh"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/bodyscan/grid",
    component: _038c92f2,
    name: "groups-groupId-patients-patientId-bodyscan-grid___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _3d52b332,
    name: "groups-groupId-patients-patientId-exams-deleted___de"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/bodyscan/grid",
    component: _038c92f2,
    name: "groups-groupId-patients-patientId-bodyscan-grid___el"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _3d52b332,
    name: "groups-groupId-patients-patientId-exams-deleted___el"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/bodyscan/grid",
    component: _038c92f2,
    name: "groups-groupId-patients-patientId-bodyscan-grid___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _3d52b332,
    name: "groups-groupId-patients-patientId-exams-deleted___en-gb"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/bodyscan/grid",
    component: _038c92f2,
    name: "groups-groupId-patients-patientId-bodyscan-grid___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _3d52b332,
    name: "groups-groupId-patients-patientId-exams-deleted___es"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _571b7c68,
    name: "fitness-groups-groupId-patients-patientId-exams-deleted___en"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/bodyscan/grid",
    component: _038c92f2,
    name: "groups-groupId-patients-patientId-bodyscan-grid___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _3d52b332,
    name: "groups-groupId-patients-patientId-exams-deleted___fr"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/bodyscan/grid",
    component: _038c92f2,
    name: "groups-groupId-patients-patientId-bodyscan-grid___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _3d52b332,
    name: "groups-groupId-patients-patientId-exams-deleted___it"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _52cb7947,
    name: "keto-groups-groupId-patients-patientId-exams-deleted___en"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/bodyscan/grid",
    component: _038c92f2,
    name: "groups-groupId-patients-patientId-bodyscan-grid___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _3d52b332,
    name: "groups-groupId-patients-patientId-exams-deleted___nl-be"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/bodyscan/grid",
    component: _038c92f2,
    name: "groups-groupId-patients-patientId-bodyscan-grid___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _3d52b332,
    name: "groups-groupId-patients-patientId-exams-deleted___nl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/bodyscan/grid",
    component: _038c92f2,
    name: "groups-groupId-patients-patientId-bodyscan-grid___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _3d52b332,
    name: "groups-groupId-patients-patientId-exams-deleted___pl"
  }, {
    path: "/regional/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _e07e58de,
    name: "regional-groups-groupId-patients-patientId-exams-deleted___en"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/bodyscan/grid",
    component: _038c92f2,
    name: "groups-groupId-patients-patientId-bodyscan-grid___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _3d52b332,
    name: "groups-groupId-patients-patientId-exams-deleted___zh"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId",
    component: _1b079974,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _52451e70,
    name: "groups-groupId-patients-patientId-exams-examId___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/overview/:formulaId?",
    component: _27637eda,
    name: "groups-groupId-patients-patientId-overview-formulaId___de"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId",
    component: _1b079974,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId___el"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _52451e70,
    name: "groups-groupId-patients-patientId-exams-examId___el"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/overview/:formulaId?",
    component: _27637eda,
    name: "groups-groupId-patients-patientId-overview-formulaId___el"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId",
    component: _1b079974,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _52451e70,
    name: "groups-groupId-patients-patientId-exams-examId___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/overview/:formulaId?",
    component: _27637eda,
    name: "groups-groupId-patients-patientId-overview-formulaId___en-gb"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId",
    component: _1b079974,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _52451e70,
    name: "groups-groupId-patients-patientId-exams-examId___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/overview/:formulaId?",
    component: _27637eda,
    name: "groups-groupId-patients-patientId-overview-formulaId___es"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _ccdea1ee,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _b19a4242,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _aee71142,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _a2c262d4,
    name: "fitness-groups-groupId-patients-patientId-exams-examId___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _403ceaa6,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _95c71ed0,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _1274a9e0,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId___en"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId",
    component: _1b079974,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _52451e70,
    name: "groups-groupId-patients-patientId-exams-examId___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/overview/:formulaId?",
    component: _27637eda,
    name: "groups-groupId-patients-patientId-overview-formulaId___fr"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId",
    component: _1b079974,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _52451e70,
    name: "groups-groupId-patients-patientId-exams-examId___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/overview/:formulaId?",
    component: _27637eda,
    name: "groups-groupId-patients-patientId-overview-formulaId___it"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _513bb104,
    name: "keto-groups-groupId-patients-patientId-bia-biaId___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _7695389a,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _60b4a44c,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _53fa46bb,
    name: "keto-groups-groupId-patients-patientId-exams-examId___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _601461a8,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _5163f3bd,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _a287a5f6,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId___en"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId",
    component: _1b079974,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _52451e70,
    name: "groups-groupId-patients-patientId-exams-examId___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/overview/:formulaId?",
    component: _27637eda,
    name: "groups-groupId-patients-patientId-overview-formulaId___nl-be"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId",
    component: _1b079974,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _52451e70,
    name: "groups-groupId-patients-patientId-exams-examId___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/overview/:formulaId?",
    component: _27637eda,
    name: "groups-groupId-patients-patientId-overview-formulaId___nl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId",
    component: _1b079974,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _52451e70,
    name: "groups-groupId-patients-patientId-exams-examId___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/overview/:formulaId?",
    component: _27637eda,
    name: "groups-groupId-patients-patientId-overview-formulaId___pl"
  }, {
    path: "/regional/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _8b7ab59e,
    name: "regional-groups-groupId-patients-patientId-exams-examId___en"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId",
    component: _1b079974,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _52451e70,
    name: "groups-groupId-patients-patientId-exams-examId___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/overview/:formulaId?",
    component: _27637eda,
    name: "groups-groupId-patients-patientId-overview-formulaId___zh"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports",
    component: _33a19e6f,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _3af06818,
    name: "groups-groupId-patients-patientId-exams-examId-bia___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _a18ac32e,
    name: "groups-groupId-patients-patientId-exams-examId-estimates___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?/hydra",
    component: _49988080,
    name: "groups-groupId-patients-patientId-exams-examId-hydra___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?/interpretive",
    component: _30be9efd,
    name: "groups-groupId-patients-patientId-exams-examId-interpretive___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?/measures",
    component: _9944f5b2,
    name: "groups-groupId-patients-patientId-exams-examId-measures___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?/percentiles",
    component: _e4f06418,
    name: "groups-groupId-patients-patientId-exams-examId-percentiles___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _d884baf6,
    name: "groups-groupId-patients-patientId-exams-examId-reports___de"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports",
    component: _33a19e6f,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports___el"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _3af06818,
    name: "groups-groupId-patients-patientId-exams-examId-bia___el"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _a18ac32e,
    name: "groups-groupId-patients-patientId-exams-examId-estimates___el"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/exams/:examId?/hydra",
    component: _49988080,
    name: "groups-groupId-patients-patientId-exams-examId-hydra___el"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/exams/:examId?/interpretive",
    component: _30be9efd,
    name: "groups-groupId-patients-patientId-exams-examId-interpretive___el"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/exams/:examId?/measures",
    component: _9944f5b2,
    name: "groups-groupId-patients-patientId-exams-examId-measures___el"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/exams/:examId?/percentiles",
    component: _e4f06418,
    name: "groups-groupId-patients-patientId-exams-examId-percentiles___el"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _d884baf6,
    name: "groups-groupId-patients-patientId-exams-examId-reports___el"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports",
    component: _33a19e6f,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _3af06818,
    name: "groups-groupId-patients-patientId-exams-examId-bia___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _a18ac32e,
    name: "groups-groupId-patients-patientId-exams-examId-estimates___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?/hydra",
    component: _49988080,
    name: "groups-groupId-patients-patientId-exams-examId-hydra___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?/interpretive",
    component: _30be9efd,
    name: "groups-groupId-patients-patientId-exams-examId-interpretive___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?/measures",
    component: _9944f5b2,
    name: "groups-groupId-patients-patientId-exams-examId-measures___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?/percentiles",
    component: _e4f06418,
    name: "groups-groupId-patients-patientId-exams-examId-percentiles___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _d884baf6,
    name: "groups-groupId-patients-patientId-exams-examId-reports___en-gb"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports",
    component: _33a19e6f,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _3af06818,
    name: "groups-groupId-patients-patientId-exams-examId-bia___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _a18ac32e,
    name: "groups-groupId-patients-patientId-exams-examId-estimates___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?/hydra",
    component: _49988080,
    name: "groups-groupId-patients-patientId-exams-examId-hydra___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?/interpretive",
    component: _30be9efd,
    name: "groups-groupId-patients-patientId-exams-examId-interpretive___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?/measures",
    component: _9944f5b2,
    name: "groups-groupId-patients-patientId-exams-examId-measures___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?/percentiles",
    component: _e4f06418,
    name: "groups-groupId-patients-patientId-exams-examId-percentiles___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _d884baf6,
    name: "groups-groupId-patients-patientId-exams-examId-reports___es"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _1ce94af1,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-bia___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _189a9e4b,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fitnessBia___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _7142b74d,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fluidRetention___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _139a907c,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-qualitative___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _b986ef20,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-quantitative___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _bf4ea3e8,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _02a4ca4d,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId-hr___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _dd45b0da,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId-radar___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _d3b82c84,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-bia___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _527c2aba,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _59b9a244,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fitnessBia___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _c5ca7340,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fluidRetention___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _5decf26f,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-functional___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _b7ba6414,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-hr___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _7d62b2d2,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-load___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _e6878ce2,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-qualitative___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _44397f7a,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-quantitative___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _679e85c2,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/skinfoldRadar",
    component: _2c31456c,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-skinfoldRadar___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _2483dede,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-somatotype___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _358fcb78,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-zscore___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _c781a690,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId-functional___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _c425be68,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _ddc17370,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId-load___en"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports",
    component: _33a19e6f,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _3af06818,
    name: "groups-groupId-patients-patientId-exams-examId-bia___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _a18ac32e,
    name: "groups-groupId-patients-patientId-exams-examId-estimates___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?/hydra",
    component: _49988080,
    name: "groups-groupId-patients-patientId-exams-examId-hydra___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?/interpretive",
    component: _30be9efd,
    name: "groups-groupId-patients-patientId-exams-examId-interpretive___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?/measures",
    component: _9944f5b2,
    name: "groups-groupId-patients-patientId-exams-examId-measures___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?/percentiles",
    component: _e4f06418,
    name: "groups-groupId-patients-patientId-exams-examId-percentiles___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _d884baf6,
    name: "groups-groupId-patients-patientId-exams-examId-reports___fr"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports",
    component: _33a19e6f,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _3af06818,
    name: "groups-groupId-patients-patientId-exams-examId-bia___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _a18ac32e,
    name: "groups-groupId-patients-patientId-exams-examId-estimates___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?/hydra",
    component: _49988080,
    name: "groups-groupId-patients-patientId-exams-examId-hydra___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?/interpretive",
    component: _30be9efd,
    name: "groups-groupId-patients-patientId-exams-examId-interpretive___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?/measures",
    component: _9944f5b2,
    name: "groups-groupId-patients-patientId-exams-examId-measures___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?/percentiles",
    component: _e4f06418,
    name: "groups-groupId-patients-patientId-exams-examId-percentiles___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _d884baf6,
    name: "groups-groupId-patients-patientId-exams-examId-reports___it"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _69d28fac,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-bia___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _560ebda0,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fitnessBia___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _112a809c,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fluidRetention___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _74d5dd06,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-qualitative___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _7fb533d6,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-quantitative___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _2680b671,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _afff7b1c,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId-hr___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _8f1343e4,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId-radar___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _584db1ba,
    name: "keto-groups-groupId-patients-patientId-exams-examId-bia___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _ce50d2c4,
    name: "keto-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _063ca934,
    name: "keto-groups-groupId-patients-patientId-exams-examId-estimates___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _535605d9,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fitnessBia___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _ec78b04a,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fluidRetention___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _43c06d2c,
    name: "keto-groups-groupId-patients-patientId-exams-examId-functional___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _5bcdcff1,
    name: "keto-groups-groupId-patients-patientId-exams-examId-hr___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/ketone",
    component: _5e608c33,
    name: "keto-groups-groupId-patients-patientId-exams-examId-ketone___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _1355bbe6,
    name: "keto-groups-groupId-patients-patientId-exams-examId-load___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _12e441f4,
    name: "keto-groups-groupId-patients-patientId-exams-examId-qualitative___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _1cbc447e,
    name: "keto-groups-groupId-patients-patientId-exams-examId-quantitative___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _8e4cc2cc,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _6df0e78c,
    name: "keto-groups-groupId-patients-patientId-exams-examId-somatotype___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/weightLoss",
    component: _2e7ac522,
    name: "keto-groups-groupId-patients-patientId-exams-examId-weightLoss___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _2968b282,
    name: "keto-groups-groupId-patients-patientId-exams-examId-zscore___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _44b27a9d,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId-functional___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _3a34a3f1,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _600f6afa,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId-load___en"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports",
    component: _33a19e6f,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _3af06818,
    name: "groups-groupId-patients-patientId-exams-examId-bia___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _a18ac32e,
    name: "groups-groupId-patients-patientId-exams-examId-estimates___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?/hydra",
    component: _49988080,
    name: "groups-groupId-patients-patientId-exams-examId-hydra___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?/interpretive",
    component: _30be9efd,
    name: "groups-groupId-patients-patientId-exams-examId-interpretive___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?/measures",
    component: _9944f5b2,
    name: "groups-groupId-patients-patientId-exams-examId-measures___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?/percentiles",
    component: _e4f06418,
    name: "groups-groupId-patients-patientId-exams-examId-percentiles___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _d884baf6,
    name: "groups-groupId-patients-patientId-exams-examId-reports___nl-be"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports",
    component: _33a19e6f,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _3af06818,
    name: "groups-groupId-patients-patientId-exams-examId-bia___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _a18ac32e,
    name: "groups-groupId-patients-patientId-exams-examId-estimates___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?/hydra",
    component: _49988080,
    name: "groups-groupId-patients-patientId-exams-examId-hydra___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?/interpretive",
    component: _30be9efd,
    name: "groups-groupId-patients-patientId-exams-examId-interpretive___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?/measures",
    component: _9944f5b2,
    name: "groups-groupId-patients-patientId-exams-examId-measures___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?/percentiles",
    component: _e4f06418,
    name: "groups-groupId-patients-patientId-exams-examId-percentiles___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _d884baf6,
    name: "groups-groupId-patients-patientId-exams-examId-reports___nl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports",
    component: _33a19e6f,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _3af06818,
    name: "groups-groupId-patients-patientId-exams-examId-bia___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _a18ac32e,
    name: "groups-groupId-patients-patientId-exams-examId-estimates___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?/hydra",
    component: _49988080,
    name: "groups-groupId-patients-patientId-exams-examId-hydra___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?/interpretive",
    component: _30be9efd,
    name: "groups-groupId-patients-patientId-exams-examId-interpretive___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?/measures",
    component: _9944f5b2,
    name: "groups-groupId-patients-patientId-exams-examId-measures___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?/percentiles",
    component: _e4f06418,
    name: "groups-groupId-patients-patientId-exams-examId-percentiles___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _d884baf6,
    name: "groups-groupId-patients-patientId-exams-examId-reports___pl"
  }, {
    path: "/regional/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _7a72ab19,
    name: "regional-groups-groupId-patients-patientId-exams-examId-bia___en"
  }, {
    path: "/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _74f524e4,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports___en"
  }, {
    path: "/regional/groups/:groupId/patients/:patientId?/exams/:examId?/vector",
    component: _65431074,
    name: "regional-groups-groupId-patients-patientId-exams-examId-vector___en"
  }, {
    path: "/regional/groups/:groupId/patients/:patientId?/histories/:historyId?/attachments",
    component: _198881a8,
    name: "regional-groups-groupId-patients-patientId-histories-historyId-attachments___en"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports",
    component: _33a19e6f,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _3af06818,
    name: "groups-groupId-patients-patientId-exams-examId-bia___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _a18ac32e,
    name: "groups-groupId-patients-patientId-exams-examId-estimates___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?/hydra",
    component: _49988080,
    name: "groups-groupId-patients-patientId-exams-examId-hydra___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?/interpretive",
    component: _30be9efd,
    name: "groups-groupId-patients-patientId-exams-examId-interpretive___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?/measures",
    component: _9944f5b2,
    name: "groups-groupId-patients-patientId-exams-examId-measures___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?/percentiles",
    component: _e4f06418,
    name: "groups-groupId-patients-patientId-exams-examId-percentiles___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _d884baf6,
    name: "groups-groupId-patients-patientId-exams-examId-reports___zh"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?/reports/edit",
    component: _23ad9c27,
    name: "groups-groupId-patients-patientId-exams-examId-reports-edit___de"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/exams/:examId?/reports/edit",
    component: _23ad9c27,
    name: "groups-groupId-patients-patientId-exams-examId-reports-edit___el"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?/reports/edit",
    component: _23ad9c27,
    name: "groups-groupId-patients-patientId-exams-examId-reports-edit___en-gb"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?/reports/edit",
    component: _23ad9c27,
    name: "groups-groupId-patients-patientId-exams-examId-reports-edit___es"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?/reports/edit",
    component: _23ad9c27,
    name: "groups-groupId-patients-patientId-exams-examId-reports-edit___fr"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?/reports/edit",
    component: _23ad9c27,
    name: "groups-groupId-patients-patientId-exams-examId-reports-edit___it"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?/reports/edit",
    component: _23ad9c27,
    name: "groups-groupId-patients-patientId-exams-examId-reports-edit___nl-be"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?/reports/edit",
    component: _23ad9c27,
    name: "groups-groupId-patients-patientId-exams-examId-reports-edit___nl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?/reports/edit",
    component: _23ad9c27,
    name: "groups-groupId-patients-patientId-exams-examId-reports-edit___pl"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?/reports/edit",
    component: _23ad9c27,
    name: "groups-groupId-patients-patientId-exams-examId-reports-edit___zh"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?",
    component: _1b143db0,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug___de"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?",
    component: _1b143db0,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug___el"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?",
    component: _1b143db0,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug___en-gb"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?",
    component: _1b143db0,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug___es"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?",
    component: _1b143db0,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug___fr"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?",
    component: _1b143db0,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug___it"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?",
    component: _1b143db0,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug___nl-be"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?",
    component: _1b143db0,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug___nl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?",
    component: _1b143db0,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug___pl"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?",
    component: _1b143db0,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug___zh"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/iframe",
    component: _f6094784,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-iframe___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/new",
    component: _cb6a3d68,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-new___de"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/iframe",
    component: _f6094784,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-iframe___el"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/new",
    component: _cb6a3d68,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-new___el"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/iframe",
    component: _f6094784,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-iframe___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/new",
    component: _cb6a3d68,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-new___en-gb"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/iframe",
    component: _f6094784,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-iframe___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/new",
    component: _cb6a3d68,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-new___es"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/iframe",
    component: _f6094784,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-iframe___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/new",
    component: _cb6a3d68,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-new___fr"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/iframe",
    component: _f6094784,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-iframe___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/new",
    component: _cb6a3d68,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-new___it"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/iframe",
    component: _f6094784,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-iframe___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/new",
    component: _cb6a3d68,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-new___nl-be"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/iframe",
    component: _f6094784,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-iframe___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/new",
    component: _cb6a3d68,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-new___nl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/iframe",
    component: _f6094784,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-iframe___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/new",
    component: _cb6a3d68,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-new___pl"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/iframe",
    component: _f6094784,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-iframe___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/new",
    component: _cb6a3d68,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-new___zh"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug",
    component: _303c5ca7,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId",
    component: _923e8f3e,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId___de"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug",
    component: _303c5ca7,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug___el"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId",
    component: _923e8f3e,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId___el"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug",
    component: _303c5ca7,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId",
    component: _923e8f3e,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId___en-gb"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug",
    component: _303c5ca7,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId",
    component: _923e8f3e,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId___es"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _2e8b57ac,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _7e289bf7,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___en"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug",
    component: _303c5ca7,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId",
    component: _923e8f3e,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId___fr"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug",
    component: _303c5ca7,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId",
    component: _923e8f3e,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId___it"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _a3405036,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _53758b48,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___en"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug",
    component: _303c5ca7,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId",
    component: _923e8f3e,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId___nl-be"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug",
    component: _303c5ca7,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId",
    component: _923e8f3e,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId___nl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug",
    component: _303c5ca7,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId",
    component: _923e8f3e,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId___pl"
  }, {
    path: "/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _3fafbc52,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___en"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug",
    component: _303c5ca7,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId",
    component: _923e8f3e,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId___zh"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug/iframe",
    component: _0004509f,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug-iframe___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId/iframe",
    component: _4deb6c25,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId-iframe___de"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug/iframe",
    component: _0004509f,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug-iframe___el"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId/iframe",
    component: _4deb6c25,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId-iframe___el"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug/iframe",
    component: _0004509f,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug-iframe___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId/iframe",
    component: _4deb6c25,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId-iframe___en-gb"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug/iframe",
    component: _0004509f,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug-iframe___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId/iframe",
    component: _4deb6c25,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId-iframe___es"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _5745497c,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _6f9ffb4f,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___en"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug/iframe",
    component: _0004509f,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug-iframe___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId/iframe",
    component: _4deb6c25,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId-iframe___fr"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug/iframe",
    component: _0004509f,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug-iframe___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId/iframe",
    component: _4deb6c25,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId-iframe___it"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _46503d21,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _1b17298a,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___en"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug/iframe",
    component: _0004509f,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug-iframe___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId/iframe",
    component: _4deb6c25,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId-iframe___nl-be"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug/iframe",
    component: _0004509f,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug-iframe___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId/iframe",
    component: _4deb6c25,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId-iframe___nl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug/iframe",
    component: _0004509f,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug-iframe___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId/iframe",
    component: _4deb6c25,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId-iframe___pl"
  }, {
    path: "/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _42063358,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___en"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug/iframe",
    component: _0004509f,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug-iframe___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId/iframe",
    component: _4deb6c25,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId-iframe___zh"
  }, {
    path: "/groups/:groupId",
    component: _3a3579f8,
    name: "groups-groupId___en"
  }, {
    path: "/groups/:groupId/bia",
    component: _1cb8a1ac,
    name: "groups-groupId-bia___en"
  }, {
    path: "/groups/:groupId/deleted",
    component: _6adb904b,
    name: "groups-groupId-deleted___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?",
    component: _c504ead6,
    name: "groups-groupId-patients-patientId___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/activities",
    component: _175ef6f0,
    name: "groups-groupId-patients-patientId-activities___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/bodyscan",
    component: _179c8654,
    name: "groups-groupId-patients-patientId-bodyscan___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/grid",
    component: _00c09153,
    name: "groups-groupId-patients-patientId-grid___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/schedule",
    component: _ee4374b8,
    name: "groups-groupId-patients-patientId-schedule___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/bodyscan/grid",
    component: _038c92f2,
    name: "groups-groupId-patients-patientId-bodyscan-grid___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _3d52b332,
    name: "groups-groupId-patients-patientId-exams-deleted___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId",
    component: _1b079974,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _52451e70,
    name: "groups-groupId-patients-patientId-exams-examId___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/overview/:formulaId?",
    component: _27637eda,
    name: "groups-groupId-patients-patientId-overview-formulaId___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports",
    component: _33a19e6f,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _3af06818,
    name: "groups-groupId-patients-patientId-exams-examId-bia___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _a18ac32e,
    name: "groups-groupId-patients-patientId-exams-examId-estimates___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?/hydra",
    component: _49988080,
    name: "groups-groupId-patients-patientId-exams-examId-hydra___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?/interpretive",
    component: _30be9efd,
    name: "groups-groupId-patients-patientId-exams-examId-interpretive___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?/measures",
    component: _9944f5b2,
    name: "groups-groupId-patients-patientId-exams-examId-measures___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?/percentiles",
    component: _e4f06418,
    name: "groups-groupId-patients-patientId-exams-examId-percentiles___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _d884baf6,
    name: "groups-groupId-patients-patientId-exams-examId-reports___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?/reports/edit",
    component: _23ad9c27,
    name: "groups-groupId-patients-patientId-exams-examId-reports-edit___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?",
    component: _1b143db0,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/iframe",
    component: _f6094784,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-iframe___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/new",
    component: _cb6a3d68,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-new___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug",
    component: _303c5ca7,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId",
    component: _923e8f3e,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug/iframe",
    component: _0004509f,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug-iframe___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId/iframe",
    component: _4deb6c25,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId-iframe___en"
  }, {
    path: "/",
    component: _39b6fd33,
    name: "index___en"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
