export default (context, inject) => {
  function hboCheckPermission(id) {
    const user = JSON.parse(localStorage.getItem('akern_customer_user') || '')
    if (user?.role === 'superuser') {
      return id === user?.id
    }
    return true
  }

  inject('hboCheckPermission', hboCheckPermission)

  context.$hboCheckPermission = hboCheckPermission
}
