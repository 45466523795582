<template>
  <b-modal
    ref="addNewFields"
    id="add-new-fields"
    :title="$t('app.common.completeProfile')"
    header-text-variant="light"
    header-class="border-bottom-0"
    header-bg-variant="warning"
    footer-class="border-top-0 pt-0"
    :cancel-title="$t('app.common.cancel')"
    dialog-class="mw-75"
    size="lg"
    @ok="handleSubmit"
    @show="updateUser"
    no-close-on-backdrop
  >
    <b-form>
      <div>
        <p
          class="mb-4"
          style="font-size: medium"
          v-html="$t('app.completeFieldsModal.GDPRMessage')"
        ></p>
        <b-form-group label-for="has-no-vat">
          <b-form-checkbox v-model="hasNoVat" @change="emptyVatField">
            {{ $t('app.completeFieldsModal.hasNoVat') }}
          </b-form-checkbox>
        </b-form-group>
        <b-form-group
          :label="$t('app.completeFieldsModal.vatNumber')"
          label-for="vat-number"
          :invalid-feedback="
            errors.first('vat number')
              ? getErrorMessage('vat number', 'vatNumber')
              : ''
          "
        >
          <b-form-input
            id="vat-number"
            name="vat number"
            v-validate="'required|isVatNumber'"
            :disabled="hasNoVat"
            type="text"
            v-model="customer.vatNumber"
            :state="errors.has('vat number') ? false : null"
          />
        </b-form-group>

        <b-form-group
          :label="$t('app.completeFieldsModal.taxCode')"
          label-for="tax-code"
          :invalid-feedback="
            errors.first('tax code')
              ? getErrorMessage('tax code', 'taxCode')
              : ''
          "
        >
          <b-form-input
            id="tax-code"
            name="tax code"
            v-validate="'required|isTaxCode'"
            :formatter="upperCase"
            type="text"
            required
            v-model="customer.taxCode"
            :state="errors.has('tax code') ? false : null"
          />
        </b-form-group>

        <b-form-group
          label-for="state-input"
          :label="$t('app.common.regionProvinceState')"
        >
          <b-form-select
            id="state-input"
            v-model="customer.state"
            v-validate="'required'"
            name="state"
            :state="errors.has('state') ? false : null"
          >
            <option v-for="state in italyProvinces" :key="state" :value="state">
              {{ state }}
            </option>
          </b-form-select>

          <b-form-input
            type="text"
            v-if="customer.country !== 'it'"
            id="state-input"
            v-model="customer.state"
            v-validate="'required'"
            name="state"
            :state="errors.has('state') ? false : null"
          />
        </b-form-group>

        <b-form-group
          :label="$t('app.completeFieldsModal.city')"
          label-for="city"
          :invalid-feedback="
            errors.first('city') ? getErrorMessage('city', 'city') : ''
          "
        >
          <b-form-input
            id="city"
            type="text"
            v-validate="'required'"
            required
            name="city"
            :state="errors.has('city') ? false : null"
            v-model="customer.city"
          />
        </b-form-group>

        <b-form-group
          :label="$t('app.completeFieldsModal.cap')"
          label-for="cap"
          :invalid-feedback="
            errors.first('cap') ? getErrorMessage('cap', 'cap') : ''
          "
        >
          <b-form-input
            id="cap"
            type="number"
            v-validate="'required|isItalianCap'"
            required
            name="cap"
            :state="errors.has('cap') ? false : null"
            v-model="customer.cap"
          />
        </b-form-group>

        <b-form-group
          :label="$t('app.completeFieldsModal.address')"
          label-for="address"
          :invalid-feedback="
            errors.first('address') ? getErrorMessage('address', 'address') : ''
          "
        >
          <b-form-input
            id="address"
            type="text"
            v-validate="'required'"
            required
            name="address"
            :state="errors.has('address') ? false : null"
            v-model="customer.address"
          />
        </b-form-group>

        <b-form-group
          :label="$t('app.completeFieldsModal.occupation')"
          label-for="occupation"
          :invalid-feedback="
            errors.first('occupation')
              ? getErrorMessage('occupation', 'occupation')
              : ''
          "
        >
          <b-form-select
            id="occupation"
            v-model="customer.occupation"
            v-validate="'required'"
            name="occupation"
            :state="errors.has('occupation') ? false : null"
          >
            <option
              v-for="type in occupations"
              :key="type.value"
              :value="type.value"
            >
              {{ type.text }}
            </option>
          </b-form-select>
        </b-form-group>
        <b-form-group
          :label="$t('app.completeFieldsModal.fieldOfInterest')"
          label-for="fields-interest-select"
          :invalid-feedback="
            errors.first('fields of interest')
              ? $t(
                  `app.addNewFieldsModal.${errors.items[0].rule}.fieldOfInterest`
                )
              : ''
          "
        >
          <b-form-select
            id="fields-interest-select"
            v-model="customer.field"
            v-validate="'required'"
            name="fields of interest"
            :state="errors.has('fields of interest') ? false : null"
          >
            <option
              v-for="type in appTypes"
              :key="type.value"
              :value="type.value"
            >
              {{ type.text }}
            </option>
          </b-form-select>
        </b-form-group>

        <b-form-group
          v-if="!hasSerialNumber"
          label-for="hardware-select"
          :label="$t('app.common.deviceType')"
          :invalid-feedback="$t('app.forms.requiredField')"
        >
          <b-form-select
            id="hardware-select"
            v-model="selectedHardwareId"
            v-validate="'required'"
            name="device type"
            :state="errors.has('device type') ? false : null"
            @change="getSerialPrefix"
          >
            <option
              v-for="item in hardwareList"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </option>
          </b-form-select>
        </b-form-group>

        <b-form-group
          v-if="!hasSerialNumber"
          label-for="prefix"
          class="serial-number-select"
          :label="$t('app.common.serialNumber')"
          :invalid-feedback="getSerialError()"
        >
          <b-form-select
            id="prefix-select"
            v-model="serialPrefix"
            v-validate="'required'"
            name="prefix"
            :state="errors.has('prefix') ? false : null"
            :disabled="!prefixes.length"
            style="width: 30%"
          >
            <option v-for="(prefix, i) in prefixes" :key="i" :value="prefix">
              {{ prefix }}
            </option>
          </b-form-select>
          <b-form-input
            type="text"
            id="serial-input"
            v-model="serialNumber"
            v-validate="'required|alpha_num|isSerial'"
            name="serial"
            :state="errors.has('serial') ? false : null"
            style="width: 70%"
          />
        </b-form-group>
        <b-form-group
          label-for="gdpr"
          :invalid-feedback="$t('app.forms.requiredField')"
        >
          <b-form-checkbox
            id="gdpr"
            name="gdpr"
            required
            type="boolean"
            v-model="gdpr"
            v-validate="'required:true'"
            :state="errors.has('gdpr') ? false : null"
          >
            {{ $t('app.completeFieldsModal.acceptGDPRI')
            }}<a :href="$privacyPolicyUrl" target="_blank">{{
              $t('app.completeFieldsModal.acceptGDPRIV')
            }}</a
            >.
            {{ $t('app.completeFieldsModal.acceptGDPRII') }}
            <a :href="$akernUrl" target="_blank">Akern</a>,
            <a :href="$academyUrl" target="_blank">Academy</a>.
            {{ $t('app.completeFieldsModal.acceptGDPRIII') }}
          </b-form-checkbox>
        </b-form-group>
      </div>
    </b-form>
  </b-modal>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { Validator } from 'vee-validate'
import validationRules from '~/components/utils/validationRules'

export default {
  props: {
    fields: {
      type: Array,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    hasSerialNumber: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      gdpr: false,
      hasNoVat: false,
      serialPrefix: null,
      serialNumber: null,
      selectedHardwareId: null,
      prefixes: [],
      allHardware: [],
      hardwareList: [],
      customer: {},
      italyProvinces: [
        'Agrigento',
        'Alessandria',
        'Ancona',
        'Aosta',
        'Arezzo',
        'Ascoli Piceno',
        'Asti',
        'Avellino',
        'Bari',
        'Barletta-Andria-Trani',
        'Belluno',
        'Benevento',
        'Bergamo',
        'Biella',
        'Bologna',
        'Brescia',
        'Brindisi',
        'Cagliari',
        'Caltanissetta',
        'Campobasso',
        'Caserta',
        'Catania',
        'Catanzaro',
        'Chieti',
        'Como',
        'Cosenza',
        'Cremona',
        'Crotone',
        'Cuneo',
        'Enna',
        'Fermo',
        'Ferrara',
        'Firenze',
        'Foggia',
        'Forlì-Cesena',
        'Frosinone',
        'Genoa',
        'Gorizia',
        'Grosseto',
        'Imperia',
        'Isernia',
        'La Spezia',
        "L'Aquila",
        'Latina',
        'Lecce',
        'Lecco',
        'Livorno',
        'Lodi',
        'Lucca',
        'Macerata',
        'Mantua',
        'Massa and Carrara',
        'Matera',
        'Messina',
        'Milan',
        'Modena',
        'Monza and Brianza',
        'Naples',
        'Novara',
        'Nuoro',
        'Oristano',
        'Padua',
        'Palermo',
        'Parma',
        'Pavia',
        'Perugia',
        'Pesaro and Urbino',
        'Pescara',
        'Piacenza',
        'Pisa',
        'Pistoia',
        'Pordenone',
        'Potenza',
        'Prato',
        'Ragusa',
        'Ravenna',
        'Reggio Calabria',
        'Reggio Emilia',
        'Rieti',
        'Rimini',
        'Rome',
        'Rovigo',
        'Salerno',
        'Sassari',
        'Savona',
        'Siena',
        'Sondrio',
        'South Sardinia',
        'South Tyrol',
        'Syracuse',
        'Taranto',
        'Teramo',
        'Terni',
        'Trapani',
        'Trento',
        'Treviso',
        'Trieste',
        'Turin',
        'Udine',
        'Varese',
        'Venice',
        'Verbano-Cusio-Ossola',
        'Vercelli',
        'Verona',
        'Vibo Valentia',
        'Vicenza',
        'Viterbo'
      ]
    }
  },
  computed: {
    ...mapGetters({
      accessToken: 'user/token'
    }),
    occupations() {
      const occupations = [
        {
          text: this.$t('pages.signup.occupation.biologistAndNutritionist'),
          value: 'Biologist and Nutritionist'
        },
        {
          text: this.$t(
            'pages.signup.occupation.kinesiologistAndAthleticTrainer'
          ),
          value: 'Kinesiologist and athletic trainer'
        },
        {
          text: this.$t('pages.signup.occupation.akernContributor'),
          value: 'Akern Contributor'
        },
        {
          text: this.$t('pages.signup.occupation.dietitian'),
          value: 'Dietitian'
        },
        {
          text: this.$t('pages.signup.occupation.pharmacist'),
          value: 'Pharmacist'
        },
        {
          text: this.$t('pages.signup.occupation.physiotherapist'),
          value: 'Physiotherapist'
        },
        {
          text: this.$t('pages.signup.occupation.nurse'),
          value: 'Nurse'
        },
        {
          text: this.$t('pages.signup.occupation.doctor'),
          value: 'Doctor'
        },
        {
          text: this.$t('pages.signup.occupation.personalTrainer'),
          value: 'Personal trainer'
        },
        {
          text: this.$t('pages.signup.occupation.researcher'),
          value: 'Researcher'
        }
      ]

      occupations.sort((a, b) => {
        if (a.text < b.text) {
          return -1
        }

        if (a.text > b.text) {
          return 1
        }

        return 0
      })

      occupations.push({
        text: 'Others',
        value: 'Others'
      })

      return occupations
    },
    appTypes() {
      const types = [
        {
          text: this.$t('pages.signup.appTypes.biologistAndNutritionist'),
          value: 'Biologist and Nutritionist'
        },
        {
          text: this.$t('pages.signup.appTypes.cardiology'),
          value: 'Cardiology'
        },
        {
          text: this.$t('pages.signup.appTypes.surgery'),
          value: 'Surgery'
        },
        {
          text: this.$t('pages.signup.appTypes.diabetology'),
          value: 'Diabetology'
        },
        {
          text: this.$t('pages.signup.appTypes.dietetics'),
          value: 'Dietetics'
        },
        {
          text: this.$t(
            'pages.signup.appTypes.endocrinologyDiabetesAndMetabolism'
          ),
          value: 'Endocrinology, diabetes and metabolism'
        },
        {
          text: this.$t('pages.signup.appTypes.fitnessWellness'),
          value: 'Fitness - Wellness'
        },
        {
          text: this.$t('pages.signup.appTypes.gastroenerology'),
          value: 'Gastroenerology'
        },
        {
          text: this.$t('pages.signup.appTypes.geriatrics'),
          value: 'Geriatrics'
        },
        {
          text: this.$t('pages.signup.appTypes.gynaecology'),
          value: 'Gynaecology'
        },
        {
          text: this.$t('pages.signup.appTypes.sportsMedicine'),
          value: 'Sports medicine'
        },
        {
          text: this.$t('pages.signup.appTypes.nephrology'),
          value: 'Nephrology'
        },
        {
          text: this.$t('pages.signup.appTypes.neurology'),
          value: 'Neurology'
        },
        {
          text: this.$t('pages.signup.appTypes.oncology'),
          value: 'Oncology'
        },
        {
          text: this.$t('pages.signup.appTypes.pediatrics'),
          value: 'Pediatrics'
        },
        {
          text: this.$t('pages.signup.appTypes.pulmonology'),
          value: 'Pulmonology'
        },
        {
          text: this.$t('pages.signup.appTypes.prevention'),
          value: 'Prevention'
        },
        {
          text: this.$t(
            'pages.signup.appTypes.rehabilitationMedicineOrthopedicsResearch'
          ),
          value: 'Rehabilitation medicine Orthopedics Research'
        },
        {
          text: this.$t('pages.signup.appTypes.sportSciences'),
          value: 'Sport Sciences'
        },
        {
          text: this.$t('pages.signup.appTypes.intensiveCareMedicine'),
          value: 'Intensive care medicine'
        }
      ]

      types.sort((a, b) => {
        if (a.text < b.text) {
          return -1
        }

        if (a.text > b.text) {
          return 1
        }

        return 0
      })

      types.push({
        text: this.$t('pages.signup.appTypes.others'),
        value: 'Others'
      })

      return types
    }
  },
  created() {
    Validator.extend('isSerial', {
      getMessage: () => this.$t('app.common.invalid.serial'),
      validate: value => validationRules.validateSerial(value)
    })
    Validator.extend('isTaxCode', {
      getMessage: () => this.$t('app.common.invalid.taxCode'),
      validate: value =>
        validationRules.validateTaxCode(value, this.customer.country)
    })
    Validator.extend('isVatNumber', {
      getMessage: () => this.$t('app.common.invalid.vatNumber'),
      validate: value =>
        validationRules.validateVatNumber(value, this.customer.country)
    })
    Validator.extend('isItalianCap', {
      getMessage: () => this.$t('app.common.invalid.cap'),
      validate: value =>
        validationRules.validateItalianCap(value, this.customer.country)
    })
    Validator.extend('alpha_num', {
      ...Validator.rules.alpha_num,
      getMessage: () => this.$t('app.common.invalid.alpha_num')
    })
    Validator.extend('required', {
      ...Validator.rules.required,
      getMessage: () => this.$t('app.forms.requiredField')
    })

    this.$axios
      .post('/akern-platform-backend/v1/common/hardware/list', {
        accessToken: this.accessToken
      })
      .then(response => {
        this.allHardware = response.data.hardware
        this.allHardware.reduce((accu, curr) => {
          if (!accu?.find(item => curr.name === item.name)) accu.push(curr)
          return accu
        }, this.hardwareList)
      })
  },
  methods: {
    ...mapMutations({
      receiveUser: 'user/receiveUser'
    }),
    emptyVatField(val) {
      if (!val) {
        this.customer.vatNumber = this.user.customer.vatNumber
      } else {
        this.customer.vatNumber = ''
      }
    },
    upperCase(value) {
      return value.toUpperCase()
    },
    updateUser() {
      this.clearModal()
      this.customer = { ...this.user.customer }
      this.hasNoVat = !this.customer.hasVatNumber
    },
    getSerialError() {
      const prefixError = this.errors.items.find(it => it.field === 'prefix')
      if (prefixError) {
        return this.$t('app.forms.requiredPrefix')
      }
      const serialError = this.errors.items.find(it => it.field === 'serial')
      if (serialError) {
        return serialError.rule !== 'required'
          ? this.errors.first('serial')
          : this.$t('app.forms.requiredField')
      }
      return this.$t('app.forms.requiredField')
    },
    getErrorMessage(field, error) {
      const rule = this.errors.items.find(it => it.field === field).rule
      return this.$t(`app.addNewFieldsModal.${rule}.${error}`)
    },
    getSerialPrefix(value) {
      this.prefixes = []
      const hardware = this.allHardware.find(item => {
        return item.id === value
      })?.name
      this.prefixes = this.allHardware
        .filter(item => item.name === hardware)
        ?.map(item => item.code)
    },
    handleSubmit(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.$validator.validate().then(valid => {
        if (valid) {
          const customer = { ...this.user.customer }
          Object.assign(customer, this.customer)
          const user = { ...this.user }
          user.customer = customer
          customer.devices = []
          if (
            this.serialPrefix &&
            this.serialNumber &&
            this.selectedHardwareId
          ) {
            customer.devices = [
              {
                serialNumber: this.serialPrefix + this.serialNumber,
                hardwareId: this.selectedHardwareId
              }
            ]
          }
          customer.hasVatNumber = !this.hasNoVat
          this.$axios
            .post('/akern-platform-backend/v1/common/customer/complete', {
              accessToken: this.accessToken,
              userId: this.user.id,
              customer
            })
            .then(res => {
              const user = { ...this.user }
              user.customer = res.data.customer
              this.receiveUser(user)
              this.$emit('updated')
              this.$nextTick(() => {
                this.$bvModal.hide('add-new-fields')
              })
            })
            .catch(error => {
              this.showErrorModal(this.$getErrorMessage(error))
            })
        }
      })
    },
    clearModal() {
      this.serialPrefix = null
      this.serialNumber = null
      this.selectedHardwareId = null
    },
    showErrorModal(errorMessage) {
      this.$bvModal.msgBoxOk(errorMessage, {
        title: this.$t('app.import.errorTitle'),
        centered: true,
        okVariant: 'danger',
        headerBgVariant: 'danger',
        headerTextVariant: 'light',
        headerClass: 'border-bottom-0',
        footerClass: 'border-top-0 pt-0'
      })
    }
  }
}
</script>
<style>
.serial-number-select > div {
  display: flex;
  flex-wrap: wrap;
}
</style>
