const validateTaxCode = function(value, country) {
  const taxCodeRegex =
    '^([A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Z]{1}[0-9LMNPQRSTUV]{3}[A-Z]{1})$'
  const vatNumberRegex = '^[0-9]{11}$'

  const combinedRegex = new RegExp(`(${taxCodeRegex}|${vatNumberRegex})`)

  if (!combinedRegex.test(value) && country === 'it') {
    return false
  }
  return true
}

const validateVatNumber = function(value, country) {
  const regex = new RegExp('^[0-9]{11}$')
  if (!regex.test(value) && country === 'it') {
    return false
  }
  return true
}

const validateSerial = function(value) {
  const regex = new RegExp('^20(0[1-9]|[1-9][0-9])(0[1-9]|1[0-2])[0-9]{3}$')
  if (!regex.test(value)) {
    return false
  }
  return true
}

const validatePassword = function(value) {
  const regex = new RegExp(
    '^(?=.*\\d)(?=.*[a-zA-Z])(?=.*[!-\\/:-@[-`{-~]).{10,}$'
  )
  if (!regex.test(value)) {
    return false
  }
  return true
}

const validateItalianCap = function(value, country) {
  const regex = new RegExp('^[0-9]{5}$')
  if (!regex.test(value) && country === 'it') {
    return false
  }
  return true
}

export default {
  validateTaxCode,
  validateVatNumber,
  validateSerial,
  validatePassword,
  validateItalianCap
}
